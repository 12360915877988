import React, { useEffect, useState } from 'react';
import ApplicationContainer from '../../../../../shared/state/containers/ApplicationContainer';
import OrganizationContainer from '../../../../../shared/state/containers/OrganizationContainer';
import styled from '@emotion/styled';
import AppliedApplicationTemplateContainer from '../../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import TemplateConfigurationContainer from '../../../../../shared/state/containers/TemplateConfigurationContainer';
import TelephonyDeploymentContainer from '../../../../../shared/hooks/TelephonyDeploymentContainer';
import ApplicationWithMembers from '../../../../../shared/models/applications/api/ApplicationWithMembers';
import ApplicationAnalyticsFacetContainer from '../../../../../shared/state/containers/ApplicationAnalyticsFacetContainer';
import OrgAnalyticsContainer from '../../../../../shared/hooks/OrgAnalyticsContainer';
import ApplicationBreakdown from '../../../../../shared/models/customAssistant/ApplicationBreakdown';
import DashboardCard from '../../../../../shared/components/charts/DashboardCard';
import ListStat from '../../../../../shared/models/customAssistant/ListStat';
import { color_variants_ocean_light_2 } from '../../../../../shared/constants/colors';
import _ from 'lodash';
import AnalyticsSelectField from '../../../../../shared/components/analytics/AnalyticsSelectField';
import LocationsList from './LocationsList';
import { AIAnsweringFeatureFlagId, FoodOrderingFeatureFlagId } from '../../../../../shared/constants/featureFlagIds';
import { css } from '@emotion/css';
import { getAIAnsweringModuleId, getFoodOrderingModuleId } from '../../../../../shared/constants/ModuleConsts';
import SparkPublishingIndicator from './SparkPublishingIndicator';
import SparkDeploymentContainer from '../../../../../shared/hooks/SparkDeploymentContainer';
import { style_border_default } from '../../../../../shared/constants/stylesValues';
import { breakpoint_small } from '../../../../../shared/constants/breakpoints';

const assistantImage = require('../../../../../shared/content/images/assistant.svg');
const callsImage = require('../../../../../shared/content/images/spark/calls.svg');
const minutesImage = require('../../../../../shared/content/images/spark/minutes.svg');
const topicsImage = require('../../../../../shared/content/images/spark/topics.svg');
const locationsImage = require('../../../../../shared/content/images/spark/locations.svg');
const orderPlacedImage = require('../../../../../shared/content/images/spark/orderPlaced.svg');
const orderTotalImage = require('../../../../../shared/content/images/spark/orderTotal.svg');
const averageOrderSizeImage = require('../../../../../shared/content/images/spark/averageOrderSize.svg');

interface ApplicationListViewProps {
    appsContainer: ApplicationContainer
    orgContainer: OrganizationContainer
    organizationId: string
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    templateConfigurationContainer: TemplateConfigurationContainer
    facetContainer: ApplicationAnalyticsFacetContainer
    history: any
}

export interface ApplicationModelWithPhoneNumberAndStatus extends ApplicationWithMembers {
    phoneNumber?: string
    virtualNumber?: string
    hasIncompleteFormFields?: boolean
}

export type ApplicationType = "common" | "location";

export type Month = "January" | "February" | "March" | "April" | "May" | "June" | "July" | "August" | "September" | "October" | "November" | "December";

const ApplicationDashboard: React.FC<ApplicationListViewProps> = (props) => {
    const [commonApps, setCommonApps] = useState<ApplicationModelWithPhoneNumberAndStatus[]>();
    const [locationApps, setLocationApps] = useState<ApplicationModelWithPhoneNumberAndStatus[]>();
    const [search, setSearch] = useState<string>("");
    const [isLoadingApps, setIsLoadingApps] = useState<boolean>(false);
    const [mostActiveLocations, setMostActiveLocations] = useState<ListStat[]>(null);
    const [locationsWithHighestOrderVolume, setLocationsWithHighestOrderVolume] = useState<ListStat[]>(null);
    const [month, setMonth] = useState<Month>();
    const [isLoadingIncompleteFields, setIsLoadingIncompleteFields] = useState(true);
    const [showOrderAnalytics, setShowOrderAnalytics] = useState(false);

    const telephonyDeploymentContainer = TelephonyDeploymentContainer.useContainer();
    const orgAnalyticsContainer = OrgAnalyticsContainer.useContainer();
    const trainingContainer = SparkDeploymentContainer.useContainer();

    const monthOptions = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    useEffect(() => {
        props.templateConfigurationContainer.getOrganizationTemplates(props.organizationId);
        const date = new Date();
        handleMonthChange(monthOptions[date.getMonth()] as Month);
    }, []);

    useEffect(() => {
        loadApps();
        props.appsContainer.loadFeatureFlags();
    }, [props.orgContainer.state.currentOrganization?.id, props.history.location.pathname]);

    useEffect(() => {
        if (locationApps && commonApps) {
            if (
                locationApps.every(app => app?.hasIncompleteFormFields !== undefined) &&
                commonApps.every(app => app?.hasIncompleteFormFields !== undefined)
            ) {
                setIsLoadingIncompleteFields(false);
            }
        }
    }, [locationApps, commonApps]);

    useEffect(() => {
        if (!locationApps) return;

        if (orgAnalyticsContainer.callStatistics?.applicationBreakdown) {
            const appBreakdown: ApplicationBreakdown = orgAnalyticsContainer.callStatistics.applicationBreakdown
            const topThreeApps = Object.keys(appBreakdown).sort((a, b) => appBreakdown[b].calls - appBreakdown[a].calls).slice(0, 3);
            const topThreeAppNames: ListStat[] = [];
            for (const appId of topThreeApps) {
                const app = locationApps.find(app => app.id === appId);
                if (app) {
                    topThreeAppNames.push({ name: app.name, count: appBreakdown[appId].calls.toLocaleString() });
                }
            }
            setMostActiveLocations(topThreeAppNames);
        }

        const locationWithTheMostOrders = orgAnalyticsContainer.topLocationsWithNumberOfOrdersPlaced?.applications.map(applicationRes => {
            const matchedApp = locationApps.find(app => app.id === applicationRes.applicationId);
            if (matchedApp) {
                return { name: matchedApp.name, count: applicationRes.orderCount }
            }
        })
        if (Array.isArray(locationWithTheMostOrders)) {
            setLocationsWithHighestOrderVolume([...locationWithTheMostOrders])
        }
    }, [orgAnalyticsContainer.callStatistics, locationApps])

    const getMonthIndex = (month: Month): number => {
        switch (month) {
            case "January":
                return 0;
            case "February":
                return 1;
            case "March":
                return 2;
            case "April":
                return 3;
            case "May":
                return 4;
            case "June":
                return 5;
            case "July":
                return 6;
            case "August":
                return 7;
            case "September":
                return 8;
            case "October":
                return 9;
            case "November":
                return 10;
            case "December":
                return 11;
            default:
                return 1;
        }
    };

    const handleMonthChange = (month: Month) => {
        orgAnalyticsContainer.resetStats();
        const today = new Date();
        const monthIndex = getMonthIndex(month);
        const startDateFormat = new Date(today.getFullYear(), monthIndex, 1, 0, 0, 0, 0);
        let endDateFormat;
        if (monthIndex === 11) { // If December, handle edge case
            endDateFormat = new Date(today.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
        } else {
            endDateFormat = new Date(today.getFullYear(), monthIndex + 1, 1, 0, 0, 0, 0);
        }
        // Adjust to last millisecond of previous day
        endDateFormat.setTime(endDateFormat.getTime() - 1);
        setMonth(month);
        orgAnalyticsContainer.loadOrgAnalytics(props.organizationId, { startDate: startDateFormat.toISOString(), endDate: endDateFormat.toISOString() })
    };

    const loadApps = async () => {
        setIsLoadingApps(true);
        const appsResult = await props.appsContainer.searchForOrgApplications(props.organizationId,
            1, 30, "CreatedDateDescending", "", true
        );
        let newApps: ApplicationModelWithPhoneNumberAndStatus[] = appsResult?.data?.applications ?? [];
        const appliedTemplateLoadTasks = [];
        const telephonyDeploymentLoadTasks = [];
        newApps = newApps.filter(app => app.featureFlags.some(flag => flag.id === FoodOrderingFeatureFlagId || flag.id === AIAnsweringFeatureFlagId));   
        newApps = newApps.filter(app => {
            if (newApps?.some(a => a.manageMenuApplicationId === app.id) === true ||
                (app?.name?.toLowerCase()?.startsWith("x -") && app?.name?.toLowerCase()?.endsWith("menu")) ||
                app?.name?.toLowerCase()?.includes("manage menu") ||
                app?.name?.toLowerCase()?.includes("manage entities")
            ) {
                return false;
            }       
            
            return true;
        });
            
            
        
 
        for (let newApp of newApps) {
            appliedTemplateLoadTasks.push(props.appliedAppTemplateContainer.loadAppliedTemplates(newApp.id));
            telephonyDeploymentLoadTasks.push(telephonyDeploymentContainer.getTelephonyDeployments(newApp.id));
        }

        await Promise.all(appliedTemplateLoadTasks);

        for (let newApp of newApps) {
            const appAppliedTemplates = props.appliedAppTemplateContainer.state.perApplicationAppliedTemplates.find(appliedTemplate => appliedTemplate.applicationId === newApp.id);
            if (appAppliedTemplates?.appliedTemplates) {
                newApp.hasIncompleteFormFields = appAppliedTemplates.appliedTemplates.some(template => {
                    const incompleteSections = props.appliedAppTemplateContainer.getIncompleteSections(template) ?? [];
                    return incompleteSections.length > 0;
                });
            }
        }

        const telephonyDeploymentResults = await Promise.all(telephonyDeploymentLoadTasks);

        for (const depResult of telephonyDeploymentResults) {
            if (depResult?.data?.length) {
                const appWithDep = newApps.find(app => app.id === depResult.data[0].applicationId);
                if (appWithDep) {
                    appWithDep.phoneNumber = depResult.data[0].phoneNumber;
                    appWithDep.virtualNumber = depResult.data[0].virtualNumber;
                }
            }
        }

        newApps = _.sortBy(newApps, app => app.name);
        newApps = _.sortBy(newApps, app => app.hasIncompleteFormFields);

        const appsThatAllowTemplating = newApps.filter((app) => app.allowsTemplating);
        const aiAnsweringCommonApps = appsThatAllowTemplating.filter((app) => app.featureFlags?.some((flag) => flag.id === AIAnsweringFeatureFlagId)) ?? [];
        if (aiAnsweringCommonApps.length) {
            let aiAnsweringCommonApp: ApplicationModelWithPhoneNumberAndStatus;
            let foodOrderingCommonApp: ApplicationModelWithPhoneNumberAndStatus;
            for (const commonApp of aiAnsweringCommonApps) {
                const commonAppAppliedTemplates = await props.appliedAppTemplateContainer.loadAppliedTemplates(commonApp.id);
                for (const commonAppAppliedTemplate of commonAppAppliedTemplates.data) {
                    if (getFoodOrderingModuleId() === commonAppAppliedTemplate.templateForm.templateConfigurationId) {
                        foodOrderingCommonApp = commonApp;
                    }
                    if (getAIAnsweringModuleId() === commonAppAppliedTemplate.templateForm.templateConfigurationId) {
                        aiAnsweringCommonApp = commonApp;
                        break;
                    }
                }
            }
            if (!aiAnsweringCommonApp) {
                if (foodOrderingCommonApp) {
                    setCommonApps([foodOrderingCommonApp]);
                } else {
                    setCommonApps([]);
                }
            } else {
                setCommonApps([aiAnsweringCommonApp]);
            }
        } else {
            setCommonApps([]);
        }

        const appsThatDontAllowTemplating = newApps.filter((app) => !app.allowsTemplating);
        if (appsThatDontAllowTemplating.some(app => app.featureFlags.some(flag => flag.id === FoodOrderingFeatureFlagId))) {
            setShowOrderAnalytics(true);
        }

        setLocationApps(appsThatDontAllowTemplating);
        setIsLoadingApps(false);
    };

    const handleSearch = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(searchEvent.target.value);
    };

    const handleSearchKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            loadApps();
        }
    };

    const formatNumber = (num: number) => {
        if (num === undefined) return;
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 })
    }

    return (
        <DashboardPageWrapper>
            <SectionsWrapper>
                <LocationsSectionWrapper>
                    <LocationAppListDashboardCard
                        title="Locations"
                        titleIcon={assistantImage}
                        taskView={true}
                        taskComponent={<LocationsList
                            isLoadingIncompleteFields={isLoadingIncompleteFields}
                            commonApps={commonApps}
                            isLoadingApps={isLoadingApps}
                            search={search}
                            handleSearch={handleSearch}
                            handleSearchKeyDown={handleSearchKeyDown}
                            history={props.history}
                            appsContainer={props.appsContainer}
                            orgContainer={props.orgContainer}
                            appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                            facetContainer={props.facetContainer}
                            organizationId={props.organizationId}
                            locationApps={locationApps}
                            tooltip={`Info & responses applicable to all ${props.orgContainer.state.currentOrganization.name + " " ?? ""} restaurant locations`}
                        />}
                    />
                </LocationsSectionWrapper>
                <InsightsSectionWrapper>
                    <StyledAnalyticsSelectField
                        label="Month:"
                        options={monthOptions.map(s => ({ label: s, value: s }))}
                        value={month}
                        onChange={(e) => handleMonthChange(e.value)}
                    />
                    <StatViewWrapper>
                        <TotalCallsDashboardCard
                            title="Calls"
                            subtitle={month ? month : "(Month)"}
                            numberStatView={true}
                            numberStat={formatNumber(orgAnalyticsContainer.callStatistics?.calls)}
                            isLoading={orgAnalyticsContainer.loadingCallStatistics || !orgAnalyticsContainer.callStatistics}
                            month={month}
                            useBackgroundDesign={true}
                            backgroundImage={callsImage}
                            backgroundImageStyle={numberStatViewImageStyle}
                        />
                        <TotalMinutesDashboardCard
                            title="Minutes"
                            subtitle={month ? month : "(Month)"}
                            numberStatView={true}
                            numberStat={orgAnalyticsContainer.callStatistics?.minutes.toFixed()}
                            isLoading={orgAnalyticsContainer.loadingCallStatistics || !orgAnalyticsContainer.callStatistics}
                            month={month}
                            useBackgroundDesign={true}
                            backgroundImage={minutesImage}
                            backgroundImageStyle={numberStatViewImageStyle}
                        />
                    </StatViewWrapper>
                    {showOrderAnalytics ?
                        <>
                            <StatViewWrapper>
                                <OrdersPlacedDashboardCard
                                    title="Orders Placed"
                                    subtitle={month ? month : "(Month)"}
                                    numberStatView={true}
                                    numberStat={orgAnalyticsContainer.orderPlacedCount}
                                    isLoading={orgAnalyticsContainer.loadingOrdersPlaced || typeof orgAnalyticsContainer.orderPlacedCount !== "number"}
                                    month={month}
                                    useBackgroundDesign={true}
                                    backgroundImage={orderPlacedImage}
                                    backgroundImageStyle={numberStatViewImageStyle}
                                />
                                {/* <ConversationSuccessRateDashboardCard
                                    title="Conversation Success Rate"
                                    subtitle={month ? month : "(Month)"}
                                    numberStatView={true}
                                    numberStat={orgAnalyticsContainer?.conversationalSuccessRate}
                                    numberStatSignRight='%'
                                    isLoading={orgAnalyticsContainer.loadingConversationalSucess || !orgAnalyticsContainer?.conversationalSuccessRate}
                                    month={month}
                                    useBackgroundDesign={true}
                                    backgroundImage={conversationalSuccessImage}
                                    backgroundImageStyle={numberStatViewImageStyle}
                                    toolTipPlace='left'
                                    toolTipText='Calls marked as Conversational Success. This includes AI Answering queries, immediate hangups, and successful transfers.'
                                /> */}
                                <TopTopicsDashboardCard
                                    title="Top Topics"
                                    subtitle={month ? month : "(Month)"}
                                    listStatView={true}
                                    listStat={orgAnalyticsContainer.topIntents}
                                    singularCountType="Inquiry"
                                    pluralCountType="Inquiries"
                                    isLoading={orgAnalyticsContainer.loadingTopIntents || !orgAnalyticsContainer.topIntents}
                                    month={month}
                                    noDataText={`There are no topic inquiries ${month ? `for the month of ${month}` : 'this month'}. If you see calls, but no data here, it's likely due to disabled detailed analytics or hangups.`}
                                    useBackgroundDesign={true}
                                    backgroundImage={topicsImage}
                                    backgroundImageStyle={listStatViewImageStyle}
                                />
                            </StatViewWrapper>
                            <StatViewWrapper>
                                <TotalSoldDashboardCard
                                    title="Total Sold"
                                    subtitle={month ? month : "(Month)"}
                                    numberStatView={true}
                                    numberStat={formatNumber(orgAnalyticsContainer.orderTotalStatistics?.orderTotalSum)}
                                    numberStatSignLeft="$"
                                    isLoading={orgAnalyticsContainer.loadingOrderTotalStatistics || !orgAnalyticsContainer.orderTotalStatistics}
                                    month={month}
                                    useBackgroundDesign={true}
                                    backgroundImage={orderTotalImage}
                                    backgroundImageStyle={numberStatViewImageStyle}
                                />
                                <AverageOrderSizeDashboardCard
                                    title="Average Order Size"
                                    subtitle={month ? month : "(Month)"}
                                    numberStatView={true}
                                    numberStat={formatNumber(orgAnalyticsContainer.orderTotalStatistics?.avgOrderTotal ?? 0)}
                                    numberStatSignLeft="$"
                                    isLoading={orgAnalyticsContainer.loadingOrderTotalStatistics || !orgAnalyticsContainer?.orderTotalStatistics}
                                    month={month}
                                    useBackgroundDesign={true}
                                    backgroundImage={averageOrderSizeImage}
                                    backgroundImageStyle={numberStatViewImageStyle}
                                />
                            </StatViewWrapper>
                        </>
                        :
                        <StatViewWrapper>
                            <TopTopicsDashboardCard
                                title="Top Topics"
                                subtitle={month ? month : "(Month)"}
                                listStatView={true}
                                listStat={orgAnalyticsContainer.topIntents}
                                toolTipText='Topic Count'
                                singularCountType="Inquiry"
                                pluralCountType="Inquiries"
                                isLoading={orgAnalyticsContainer.loadingTopIntents || !orgAnalyticsContainer.topIntents}
                                month={month}
                                noDataText={`There are no topic inquiries ${month ? `for the month of ${month}` : 'this month'}. If you see calls, but no data here, it's likely due to disabled detailed analytics or hangups.`}
                                useBackgroundDesign={true}
                                backgroundImage={topicsImage}
                                backgroundImageStyle={listStatViewImageStyle}
                            />
                        </StatViewWrapper>
                    }
                    <StatViewWrapper>
                        {showOrderAnalytics ?
                            <MostActiveLocationsDashboardCard
                                title="Highest Volume"
                                subtitle={month ? month : "(Month)"}
                                listStatView={true}
                                listStat={locationsWithHighestOrderVolume}
                                singularCountType="Order"
                                pluralCountType="Orders"
                                isLoading={orgAnalyticsContainer.loadingTopLocationWithHighestNumberOfOrders || !orgAnalyticsContainer.topLocationsWithNumberOfOrdersPlaced}
                                month={month}
                                noDataText={`There haven't been any orders ${month ? `during the month of ${month}` : 'this month'}.`}
                                useBackgroundDesign={true}
                                backgroundImage={locationsImage}
                                backgroundImageStyle={listStatViewImageStyle}
                            />
                            :
                            <MostActiveLocationsDashboardCard
                                title="Most Active Locations"
                                subtitle={month ? month : "(Month)"}
                                listStatView={true}
                                listStat={mostActiveLocations}
                                toolTipText='Call Count'
                                singularCountType="Call"
                                pluralCountType="Calls"
                                isLoading={orgAnalyticsContainer.loadingCallStatistics || !mostActiveLocations}
                                month={month}
                                noDataText={`There haven't been any calls ${month ? `during the month of ${month}` : 'this month'}.`}
                                useBackgroundDesign={true}
                                backgroundImage={locationsImage}
                                backgroundImageStyle={listStatViewImageStyle}
                            />
                        }
                    </StatViewWrapper>
                </InsightsSectionWrapper>
            </SectionsWrapper>
            <SparkPublishingIndicator
                trainingState={locationApps?.some(l => trainingContainer.getAppDeploymentState(l.id) === "training") ? "training" : "trained"}
                trainingMessage="Publishing in progress..."
                trainedMessage="Publishing complete"
                errorMessage="Publishing error"
            />
        </DashboardPageWrapper >
    );
};

const numberStatViewImageStyle = css`
   
`;

const listStatViewImageStyle = css`
   
`;

const StyledAnalyticsSelectField = styled(AnalyticsSelectField)`
    place-self: end;
    ${breakpoint_small} {
        place-self: auto;
    }
`;

const TotalCallsDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-right: 15px;
    background:${color_variants_ocean_light_2};
`;

const TotalMinutesDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    background:${color_variants_ocean_light_2};
`;

const TopTopicsDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    background:${color_variants_ocean_light_2};
`;

const MostActiveLocationsDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    background:${color_variants_ocean_light_2};
`;

const LocationAppListDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    background:${color_variants_ocean_light_2};
    height: 87vh;
    ${breakpoint_small} {
        height: auto;
    }
`;

const StatViewWrapper = styled.div`
    display: flex;
`;

const SectionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    height: calc(100vh - 132px);
    ${breakpoint_small} {
        flex-direction: column;
        flex-wrap: nowrap;
    }
`;

const InsightsSectionWrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr 1fr 1fr;
    gap: 15px;
    flex: 2;
    ${breakpoint_small} {
        margin-top: 16px;
    }
`;

const LocationsSectionWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr;
    margin-right: 24px;
    gap: 15px;
    flex: 3;
    ${breakpoint_small} {
        margin-right: 4px;
    }
`;

const DashboardPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px;
`;

const OrdersPlacedDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-right: 15px;
    background:${color_variants_ocean_light_2};
`;

const AverageOrderSizeDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    background:${color_variants_ocean_light_2};
`;

const TotalSoldDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-right: 15px;
    background:${color_variants_ocean_light_2};
`;


const ConversationSuccessRateDashboardCard = styled(DashboardCard)`
    border: ${style_border_default};
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
    border-radius: 8px;
    padding: 15px;
    background:${color_variants_ocean_light_2};
`;

export default ApplicationDashboard;