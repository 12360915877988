import React from 'react';
import { FormikProps } from 'formik';
import { SparkTelephonyDeploymentFormData, SparkTelephonyDeploymentProps } from './SparkTelephonyDeploymentForm';
import styled from '@emotion/styled';
import FormFieldCard from '../../../../shared/components/forms/FormFieldCard';
import TextField from '../../../../shared/components/forms/TextField';
import RadioGroup from '../../../../shared/components/forms/RadioGroup';
import RadioCardGroupWithAudio from '../../../../shared/components/forms/RadioCardGroupWithAudio';
import PanelContainer from '../../../../shared/components/structure/Panels/PanelContainer';
import ScrollablePanelBody from '../../../../shared/components/structure/ScrollablePanelBody';
import Button from '../../../../shared/components/general/Button';
import { css } from '@emotion/css';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';
import PanelHeaderContainer from '../../../../shared/components/structure/Panels/PanelHeaderContainer';
import { color_shades_dark, color_shades_lighter } from '../../../../shared/constants/colors';
import Switch from '../../../../shared/components/forms/Switch';

const instructionsIcon = require('../../../../shared/content/images/info_icon.svg');

const SparkTelephonyDeployment: React.FC<SparkTelephonyDeploymentProps & FormikProps<SparkTelephonyDeploymentFormData>> = (props) => {

    const handlePhoneNumberStartsWithBlur = () => {
        if (props.values.phoneNumberStartsWith[0] !== "1") {
            props.setFieldValue("phoneNumberStartsWith", 1 + props.values.phoneNumberStartsWith)
        }
        props.handleBlur;
    };

    const clearTelephonyDeploymentErrors = () => {
        props.clearTelephonyDeploymentErrors();
        props.setPhoneNumberDeploymentErrorText("");
    };

    const handleTelephonySubmit = () => {
        
        props.handleSubmit();

        const matchingSection = props.appendedSections.findIndex(appendedSection => appendedSection.id === props.telephonyDeploymentSection.id);

        if (!props.phoneNumberDeploymentErrorText) {
            props.setIncompleteSections([
                ...props.incompleteSections.filter(section => section.id !== props.telephonyDeploymentSection.id)
            ]);
        } else {
            props.setAppendedSections([
                Object.assign({}, props.appendedSections[matchingSection], { isComplete: false, isSelected: true })
            ])
            props.setIncompleteSections([
                ...props.incompleteSections.filter(section => section.id !== props.telephonyDeploymentSection.id),
                props.telephonyDeploymentSection
            ]);
        }

        props.handleNextSection();

    };

    const handleSkipSection = () => {
        clearTelephonyDeploymentErrors();
        props.handleNextSection();
        const matchingSection = props.appendedSections.findIndex(appendedSection => appendedSection.id === props.telephonyDeploymentSection.id);
        props.setAppendedSections([
            Object.assign({}, props.appendedSections[matchingSection], { isComplete: true, isSelected: false })
        ])
        props.setIncompleteSections([
            ...props.incompleteSections.filter(section => section.id !== props.telephonyDeploymentSection.id)
        ]);
    };

    return (
        <SparkTelephonyDeloymentWrapper>
            <PanelHeaderContainer>
                <InstructionsIcon src={instructionsIcon} />
                <InstructionsText>Complete the fields below</InstructionsText>
                <div className={buttonContainerStyle}>
                    <Button
                        disabled={false}
                        themes={["white-small"]}
                        text="Previous"
                        type="button"
                        onClick={props.handlePreviousSection}
                    />
                    <Button
                        disabled={false}
                        loading={false}
                        themes={["primary-small"]}
                        text="Save & Next"
                        type="button"
                        onClick={handleTelephonySubmit}
                    />
                </div>
            </PanelHeaderContainer>
            <SparkTelephonyDeploymentFormWrapper>
                <PanelContainer className={panelContainerStyle}>
                    <ScrollablePanelBody>
                        <form onSubmit={props.handleSubmit}>
                            <FormFieldCardsWrapper>
                                <Title>Phone Number</Title>
                                <CustomSwitch
                                    name='useVirtualNumber'
                                    label='Use Virtual Number'
                                    detail='Use a virtual number in place of a phone number.'
                                    checked={props.values.useVirtualNumber}
                                    onChange={(checked) => props.setFieldValue('useVirtualNumber', checked)}
                                />
                                {props.values.useVirtualNumber ?
                                    <FormFieldCardWrapper>
                                        <FormFieldCard
                                            title="Virtual Number"
                                            required={false}
                                            tip="Enter your virtual number below."
                                        >
                                            <TextField
                                                name="virtualNumber"
                                                value={props.values.virtualNumber}
                                                onChange={(e) => props.setFieldValue("virtualNumber", e.target.value)}
                                                label="Virtual Number"
                                                placeholder="Enter your virtual number here."
                                            />
                                        </FormFieldCard>
                                    </FormFieldCardWrapper>
                                    :
                                    <FormFieldCardWrapper>
                                        <FormFieldCard
                                            title="Area Code"
                                            required={false}
                                            tip="Enter your preferred country code and area code for your assistant's phone number here. The US and Canada country code is 1."
                                        >
                                            <TextField
                                                name="phoneNumberStartsWith"
                                                value={props.values.phoneNumberStartsWith}
                                                onChange={(e) => props.setFieldValue("phoneNumberStartsWith", e.target.value)}
                                                label="Phone Number Country Code & Area Code"
                                                placeholder="Enter your preferred country code and area code here e.g. 1508."
                                                onBlur={handlePhoneNumberStartsWithBlur}
                                            />
                                        </FormFieldCard>
                                    </FormFieldCardWrapper>
                                }
                                <FormFieldCardWrapper>
                                    <FormFieldCard
                                        title="Record Calls"
                                        required={true}
                                        tip="By enabling, you'll be able to access call recordings in the session analytics tab."
                                    >
                                        <RadioGroup
                                            onChange={(e) => props.setFieldValue("callRecordingEnabled", e.value)}
                                            value={props.values.callRecordingEnabled}
                                            options={[
                                                { label: "Call Recording Enabled", value: true },
                                                { label: "Call Recording Disabled", value: false }
                                            ]}
                                        />
                                    </FormFieldCard>
                                </FormFieldCardWrapper>
                                <FormFieldCardWrapper>
                                    <FormFieldCard
                                        title="Voice"
                                        required={true}
                                        tip="Select your assistant's voice from the list below."
                                    >
                                        <RadioCardGroupWithAudioWrapper>
                                            <RadioCardGroupWithAudio
                                                onChange={(e) => props.setFieldValue("ttsVoice", e.value)}
                                                options={props.voiceOptions}
                                                value={props.values.ttsVoice}
                                            />
                                        </RadioCardGroupWithAudioWrapper>
                                    </FormFieldCard>
                                </FormFieldCardWrapper>
                            </FormFieldCardsWrapper>
                            <ButtonWrapper>
                                <StyledSaveAndNextButton
                                    disabled={false}
                                    loading={false}
                                    themes={["primary"]}
                                    text="Save & Next"
                                    type="button"
                                    onClick={() => handleTelephonySubmit()}
                                />
                                <StyledSkipButton
                                    disabled={false}
                                    loading={false}
                                    themes={["primary"]}
                                    text="Skip"
                                    type="button"
                                    onClick={() => handleSkipSection()}
                                />
                            </ButtonWrapper>
                        </form>
                    </ScrollablePanelBody>
                </PanelContainer>
            </SparkTelephonyDeploymentFormWrapper>
        </SparkTelephonyDeloymentWrapper>
    )
}

const SparkTelephonyDeloymentWrapper = styled.div``;

const CustomSwitch = styled(Switch)`
    margin: 24px 0;
`;

const RadioCardGroupWithAudioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
`;

const FormFieldCardWrapper = styled.div`
    padding: 16px 0px;
    position: relative;
    .field-triangle {
        position: absolute;
        width: 24px;
        height: 24px;
        background: ${color_shades_lighter};
        right: -44px;
        top: 64px;
        transform: rotate(45deg);
        border-left: 1px solid ${color_shades_dark};
        border-bottom: 1px solid ${color_shades_dark};
    }
`;


const FormFieldCardsWrapper = styled.div`
    padding: 32px;
    h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    ${breakpoint_small} {
        padding: 16px;
        h3 {
            margin-bottom: 0px;
        }
    }
`;

const InstructionsIcon = styled.img`
    width: 24px;
    height: 24px;
    margin: 0 8px 0 32px;
    ${breakpoint_small} {
        display: none;
    }
`;

const InstructionsText = styled.p`
    ${breakpoint_small} {
        display: none;
    }
`;

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
    ${breakpoint_small} {
        margin-right: 0;
    }
`

const SparkTelephonyDeploymentFormWrapper = styled.div`
    h3 {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
    ${breakpoint_small} {
        padding: 16px;
        h3 {
            margin-bottom: 0px;
        }
    }
`;

const StyledSaveAndNextButton = styled(Button)`
    margin-top: 0px;
    margin-right: 32px;
    ${breakpoint_small} {
        margin-right: 0;
        width: 100px;
        font-size: 12px;
    }
`;

const StyledSkipButton = styled(Button)`
    margin-top: 0px;
    margin-right: 32px;
    ${breakpoint_small} {
        margin-right: 16px;
        width: 100px;
        font-size: 12px;
    }
`;

const panelContainerStyle = css`
    display: flex;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 88px 18px;
    ${breakpoint_small} {
        justify-content: flex-end;
    }
`;

const Title = styled.h3`
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 16px;
`;

export default SparkTelephonyDeployment;

