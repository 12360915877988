import React, { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { SparkNewTopicFormData, SparkNewTopicProps } from './SparkNewTopicForm';
import { color_shades_darker, color_shades_darkest } from '../../../../shared/constants/colors';
import styled from '@emotion/styled';
import RadioCardGroup, { RadioOption } from '../../../../shared/components/forms/RadioCardGroup';
import { css } from '@emotion/css';
import TextField from '../../../../shared/components/forms/TextField';
import ModalHeader from '../../../../shared/components/general/ModalHeader';
import FooterBar from '../../../../shared/components/structure/FooterBar';
import Button from '../../../../shared/components/general/Button';
import ModalLayout from '../../../../shared/components/structure/ModalLayout';
import CustomScrollbars from '../../../../shared/components/structure/CustomScrollbars';
import useWindowSize from '../../../../shared/hooks/UseWindowSize';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';

const respondIcon = require('../../../../shared/content/images/spark/respond.svg');
const textIcon = require('../../../../shared/content/images/spark/text.svg');
const transferIcon = require('../../../../shared/content/images/spark/transfer.svg');

const SparkNewTopic: React.FC<SparkNewTopicProps & FormikProps<SparkNewTopicFormData>> = (props) => {

    const MODAL_SCROLLBAR_SUBTRACTION: number = 200;

    const windowSize = useWindowSize();

    useEffect(() => {
        props.appliedAppTemplateContainer.loadAppliedTemplates(props.applicationId);
    }, [props.sections.length])

    const [topic, setTopic] = useState("your new topic");

    const topicResponseOptions: RadioOption[] = [
        {
            label: "Respond to the Topic",
            value: "respond",
            icon: respondIcon,
            description: 'For Example: "Yes, we are hiring. Email your resume to staff@restaurant.com. What else can I help you with?"',
            className: radioOptionCardStyle
        },
        {
            label: "Transfer the Call",
            value: "transfer",
            icon: transferIcon,
            description: 'For Example: "No problem! Transferring you to our catering department so you can learn more about our options."',
            className: radioOptionCardStyle
        },
        {
            label: "Offer a Text Message",
            value: "offerText",
            icon: textIcon,
            description: 'For Example: "I can send you a text message with our full menu. What phone number can I reach you at?"',
            className: radioOptionCardStyle
        }
    ];

    return (
        <ModalLayout isVisible={true}>
            <ModalHeader title="Add a New Topic" onClose={() => props.toggleNewTopicModal()} />
            <form onSubmit={props.handleSubmit}>
                <CustomScrollbars autoHide autoHeight autoHeightMax={(windowSize.windowSize.innerHeight * .9) - MODAL_SCROLLBAR_SUBTRACTION}>
                    <ModalBody>
                        <HeaderWrapper>
                            <SubHeader>Topic Name</SubHeader>
                            <Description>Create a brief topic name, that will be used as the form section title.</Description>
                        </HeaderWrapper>
                        <TextField
                            name="topicName"
                            value={props.values.topicName}
                            onChange={(e) => {
                                props.setFieldValue("topicName", e.target.value)
                                setTopic(e.target.value)
                            }}
                            placeholder="Enter your new topic name here. e.g. Getting Started Information"
                        />
                        <HeaderWrapper>
                            <SubHeader>Expected Response</SubHeader>
                            <Description>{`If a customer inquires about ${topic}, the assistant should:`}</Description>
                        </HeaderWrapper>
                        <RadioCardGroup
                            onChange={(e) => props.setFieldValue("topicResponseType", e.value)}
                            value={props.values.topicResponseType}
                            options={topicResponseOptions}
                        />
                    </ModalBody>
                </CustomScrollbars>
                <FooterBar>
                    <StyledCloseButton
                        disabled={props.isSubmitting}
                        loading={props.isSubmitting}
                        type="button"
                        themes={['secondary', 'end']}
                        text="Close"
                        onClick={() => props.toggleNewTopicModal()}
                    />
                    <StyledAddButton
                        disabled={props.isSubmitting}
                        loading={props.isSubmitting}
                        type="submit"
                        themes={['primary', 'start']}
                        text="Add"
                    />
                </FooterBar>
            </form>
        </ModalLayout>
    )
};

const StyledAddButton = styled(Button)`
    ${breakpoint_small} {
        margin: 0 8px 0 0;
    }
`;

const StyledCloseButton = styled(Button)`
    ${breakpoint_small} {
        margin: 0 0 0 8px;
    }
`;

const HeaderWrapper = styled.div`
    ${breakpoint_small} {
        padding: 0 8px;
    }
`;

const ModalBody = styled.div`
    padding: 0 32px;
    ${breakpoint_small} {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }        
`;

const radioOptionCardStyle = css`
    padding: 32px;
`;

const Description = styled.p`
    font-size: 14px;
    color: ${color_shades_darker};
    margin: 16px 0 16px 0;
`;

const SubHeader = styled.h2`
    margin-top: 24px;
    font-size: 18px;
    color: ${color_shades_darkest};
    font-weight: 400;
`;

export default SparkNewTopic;