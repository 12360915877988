import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import { color_shades_darkest, color_shades_lighter } from '../../../constants/colors';
import LiveChatBubble from './LiveChatBubble';
import Button from '../../../components/general/Button';
import SessionAnalyticsContainer from '../../../hooks/SessionAnalyticsContainer';
import PageError from '../../../components/general/PageError';
import AnalyticsOrderStateContainer from '../../../hooks/AnalyticsOrderStateContainer';
import TooltipWrapper from '../../../components/general/TooltipWrapper';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import { breakpoint_small } from '../../../constants/breakpoints';
import useIsMobile from '../../../hooks/UseIsMobile';

const redXIcon = require('../../../content/images/spark/red-x.svg');
const greenCheckIcon = require('../../../content/images/spark/green-check.svg');

type OrderState = "orderPlaced" | "orderNotPlaced" | string;
type ConversationalSuccess = "successful" | "unsuccessful" | string;

interface SessionReviewProps {
    applicationId: string,
    setEventsToShow: (events: any[]) => void
    events: any[],
    manualHeight?: any,
    hideBorder?: boolean,
    simple?: boolean,
    inModal?: boolean
    resetCopyButtonText?: () => void
    sessionId?: string
}

const SessionReview = (props: SessionReviewProps) => {
    const [recordingsAvailable, setRecordingsAvailable] = useState(true);
    const [clickedRecordings, setClickedRecordings] = useState(false);
    const [showOrderPlacedConfirmationModal, setShowOrderPlacedConfirmationModal] = useState(false);
    const [showConversationalSuccessConfirmationModal, setShowConversationalSuccessConfirmationModal] = useState(false);
    const [orderState, setOrderState] = useState<OrderState>();
    const [conversationalSuccess, setConversationalSuccess] = useState<ConversationalSuccess>();

    const sessionContainer = SessionAnalyticsContainer.useContainer();
    const analyticsOrderStateContainer = AnalyticsOrderStateContainer.useContainer();
    const isMobile = useIsMobile();

    const loadOrderState = (events: any[]) => {
        const itemAddedToOrderEvents = events?.find(e => e.eventType === "ItemAddedToOrder");
        const analyticsOrderState = analyticsOrderStateContainer.getStoredAnalyticsOrderState();
        if (itemAddedToOrderEvents) {
            const orderPlacedEvent = events?.find(e => e.eventType === "OrderPlaced");
            if (orderPlacedEvent) {
                setOrderState("orderPlaced");
            } else {
                const orderPlacedInLocalStorage = analyticsOrderState[events[0].sessionId]?.orderPlaced;
                if (orderPlacedInLocalStorage) {
                    setOrderState("orderPlaced");
                } else {
                    setOrderState("orderNotPlaced");
                }
            };
            const conversationalSuccessEvent = events?.find(e => e.eventType === "ConversationalSuccess");
            if (conversationalSuccessEvent) {
                setConversationalSuccess("successful");
            } else {
                const successfulConversationInLocalStorage = analyticsOrderState[events[0].sessionId]?.successfulConversation;
                if (successfulConversationInLocalStorage) {
                    setConversationalSuccess("successful");
                } else {
                    setConversationalSuccess("unsuccessful");
                }
            }
        } else {
            const conversationalSuccessEvent = events?.find(e => e.eventType === "ConversationalSuccess");
            if (conversationalSuccessEvent) {
                setConversationalSuccess("successful");
            } else {
                const successfulConversationInLocalStorage = analyticsOrderState[events[0].sessionId]?.successfulConversation;
                if (successfulConversationInLocalStorage) {
                    setConversationalSuccess("successful");
                } else {
                    setConversationalSuccess("unsuccessful");
                }
            }
        }
    };

    const loadEventsForSession = async (sessionId: string, forceLoad?: boolean) => {
        let eventsForSession = [];
        if (forceLoad) {
            eventsForSession = await sessionContainer.loadEventsForSession(props.applicationId, sessionId, forceLoad);
        } else {
            eventsForSession = await sessionContainer.loadEventsForSession(props.applicationId, sessionId);
        }
        if (eventsForSession.length > 0) {
            loadOrderState(eventsForSession);
        }
    };

    useEffect(() => {
        if (props.sessionId) {
            loadEventsForSession(props.sessionId);
        } else {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const sessionId = params.get('sessionId');
            loadEventsForSession(sessionId);
        }
    }, [window.location.href, props.sessionId]);

    useEffect(() => {
        setRecordingsAvailable(true);
        setClickedRecordings(false);
    }, [props.events, props.applicationId]);

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef<any>();
        useEffect(() => elementRef.current.scrollIntoView());
        return <div ref={elementRef} />;
    };

    const loadRecording = async (processed: boolean) => {
        const sessionId = props.events.find(e => e.sessionId?.length)?.sessionId;
        const recordingUrl = await sessionContainer.getRecordingUrl(props.applicationId, sessionId, processed);
        if (recordingUrl?.length) {
            // open url in new tab
            window.open(recordingUrl, "_blank")
        } else {
            setRecordingsAvailable(false);
        }
    };

    const renderRequestEventBubbles = (reqId: string, events: any[], idx: number) => {
        if (events?.length && events.every(e => e.eventType === "CallComplete")) {
            return <HiddenDiv key={idx}></HiddenDiv>
        }
        // request recieved on left, assistant speach started and content hit on right
        const requestRecEvents = events.filter(e => e.eventType === "RequestReceived");
        const contentHitEvents = events.filter(e => e.eventType === "ContentHit");
        const userMarkdown = generateUserMarkdown(requestRecEvents, contentHitEvents, idx === 0);
        const botMarkdown = generateBotMarkdown(events);
        const userInvalid = !userMarkdown?.length || userMarkdown === "Unknown";
        const botInvalid = !botMarkdown?.length || botMarkdown === "Unknown";
        if (userInvalid && botInvalid) {
            return <></>
        }
        return (
            <RequestDetails key={`${idx}-${reqId}`}>
                {userInvalid ?
                    <></>
                    :
                    <LiveChatBubble simple={props.simple} markdown={userMarkdown} sender={"user"} showCodeModal={() => props.setEventsToShow(events)} />
                }
                {botInvalid ?
                    <></>
                    :
                    <LiveChatBubble markdown={botMarkdown} sender={"bot"} />
                }
            </RequestDetails>
        )
    };

    const generateUserMarkdown = (requestEvents: any[], contentHitEvents: any[], first): string => {
        if (props.simple) {
            // get last events
            if (requestEvents?.length) {
                const lastRequest = requestEvents[requestEvents.length - 1];
                if (lastRequest.utterance?.length) {
                    return lastRequest.utterance;
                }
            } if (contentHitEvents?.length) {
                const lastContentHit = contentHitEvents[contentHitEvents.length - 1];
                if (lastContentHit?.originalRequest?.nlp?.utterance?.length) {
                    return lastContentHit?.originalRequest?.nlp?.utterance;
                } else if (lastContentHit && !first) {
                    return "Detailed analytics not available"
                }
            }
            return first ? "Start Conversation" : "Unknown";
        }
        let md = "";
        for (let i = 0; i < requestEvents.length; i++) {
            const event = requestEvents[i];
            let utterance = event.utterance;
            if (!utterance?.length) {
                // check if content hit array has same index
                const contentHitEvent = contentHitEvents[i];
                utterance = contentHitEvent?.originalRequest?.nlp?.utterance;
                if (!utterance && contentHitEvent && !first) {
                    utterance = "Detailed analytics not available";
                }
            }
            md = `${md}${utterance ?? (first ? "Start Conversation" : "Unknown")}\n\n`;
        }
        return md
    };

    const generateBotMarkdown = (responseEvents: any[]): string => {
        const contentHits = responseEvents.filter(e => e.eventType === "ContentHit");
        if (props.simple) {
            // get speech started
            const assistantSpeech = responseEvents.filter(e => e.eventType === "AssistantSpeechStarted");
            if (assistantSpeech?.length) {
                const lastAssistantSpeech = assistantSpeech[assistantSpeech.length - 1];
                if (lastAssistantSpeech?.speechText?.length) {
                    return stripSsml(lastAssistantSpeech?.speechText);
                } else if (lastAssistantSpeech) {
                    return "Detailed analytics not available"
                }
            }
            if (contentHits?.length) {
                const lastContentHit = contentHits[contentHits.length - 1];
                if (lastContentHit?.response?.data?.content?.length) {
                    return stripSsml(lastContentHit?.response?.data?.content);
                } else if (lastContentHit) {
                    return "Detailed analytics not available"
                }
            }
            return "Unknown";
        };
        let md = "";
        if (contentHits?.length) {
            md = `${md}#### Content Hits: ${contentHits.length}\n&nbsp;\n\n`;
        }
        const anyAssistantSpeech = responseEvents.find(e => e.eventType === "AssistantSpeechStarted");
        for (const event of responseEvents) {
            if (event.eventType == "AssistantSpeechEnded") {
                if (event.early) {
                    if (event.secondsSpoken) {
                        // round seconds spoken to one decimal
                        md = `${md}#### Assistant interrupted after ${event?.secondsSpoken?.toFixed(1) ?? "?"} seconds\n&nbsp;\n`;
                    } else {
                        md = `${md}#### Assistant interrupted\n&nbsp;\n`;
                    }
                }
            } else if (event.eventType == "AssistantSpeechStarted") {
                // compare event date of events
                // count number of assistant speech paused after this speech started
                const assistantSpeechPaused = responseEvents.filter(e =>
                    e.eventType === "AssistantSpeechPaused"
                    && e.timestamp > event.timestamp
                    && !responseEvents.some(se =>
                        se.eventType === "AssistantSpeechStarted"
                        && se.timestamp > event.timestamp
                        && se.timestamp < e.timestamp)
                );
                if (assistantSpeechPaused?.length) {
                    md = `${md}#### Assistant Speech (Paused ${assistantSpeechPaused.length} times)\n`
                } else {
                    md = `${md}#### Assistant Speech\n`;
                }
                md = `${md}${stripSsml(event?.speechText ?? "unknown")}\n\n&nbsp;\n`;
            } else if (event.eventType == "ContentHit") {
                if (!anyAssistantSpeech) {
                    md = `${md}${stripSsml(event?.response?.data?.content ?? "unknown")}\n\n&nbsp;\n\n`;
                }
            } else if (event.eventType == "OrderPlaced") {
                md = `${md}#### Order placed with ${event?.totalItems ?? 0} \n&nbsp;\n\n`;
            } else if (event.eventType == "Frustration") {
                md = `${md}#### Frustration detected \n&nbsp;\n\n`;
            } else if (event.eventType == "ItemAddedToOrder") {
                md = `${md}#### Item(s) added to order \n&nbsp;\n\n`;
            } else if (event.eventType == "CallTransferred") {
                md = `${md}#### Call transferred \n&nbsp;\n\n`;
            } else if (event.eventType == "ImmediateTransfer") {
                md = `${md}#### Immediate Transfer \n&nbsp;\n\n`;
            }
        }
        // more exact trim
        while (md.endsWith("\n") || md.endsWith("&nbsp;")) {
            // if md ends with \n, remove it
            if (md.endsWith("\n")) {
                md = md.slice(0, -1);
            }
            // if md ends with &nbsp;, remove it
            if (md.endsWith("&nbsp;")) {
                md = md.slice(0, -6);
            }
        }
        return md;
    };

    const generateCallCompleteMarkdown = (event): string => {
        let md = `Call Complete\n\nDuration: ${event.duration} seconds\n\n`;
        return md
    };

    const stripSsml = (ssml: string) => {
        return ssml.replace(/<[^>]*>/g, '');
    };

    // group by request id
    const requestIdEvents = props.events.filter(e => e.requestId?.length);
    const groupedEvents = requestIdEvents.reduce((acc, event) => {
        if (event.requestId in acc) {
            acc[event.requestId].push(event);
        } else {
            acc[event.requestId] = [event];
        }
        return acc;
    }, {} as { [requestId: string]: any[] });
    const callComplete = props.events.find(e => e.eventType === "CallComplete");

    const markAsOrderPlaced = async () => {
        const applicationId = props.applicationId;
        let sessionId: string;
        if (props.sessionId) {
            sessionId = props.sessionId;
        } else {
            sessionId = props.events.find(e => e.sessionId?.length)?.sessionId;
        }
        const markOrderAsPlacedResponse = await analyticsOrderStateContainer.markOrderAsPlaced(applicationId, sessionId);
        if (markOrderAsPlacedResponse.resultType === "Ok") {
            setOrderState("orderPlaced");
            // store state in local storage
            const analyticsOrderState = analyticsOrderStateContainer.getStoredAnalyticsOrderState();
            if (analyticsOrderState[sessionId]) {
                analyticsOrderState[sessionId].orderPlaced = true;
            } else {
                analyticsOrderState[sessionId] = { orderPlaced: true };
            }
            analyticsOrderStateContainer.storeAnalyticsOrderState(analyticsOrderState);
        }
        toggleOrderPlacedConfirmationModal();
    };

    const toggleOrderPlacedConfirmationModal = () => {
        setShowOrderPlacedConfirmationModal(!showOrderPlacedConfirmationModal);
    };

    const toggleConversationalSuccessConfirmationModal = () => {
        setShowConversationalSuccessConfirmationModal(!showConversationalSuccessConfirmationModal);
    };

    const markOrderAsConversationalSuccess = async () => {
        const applicationId = props.applicationId;
        let sessionId: string;
        if (props.sessionId) {
            sessionId = props.sessionId;
        } else {
            sessionId = props.events.find(e => e.sessionId?.length)?.sessionId;
        }
        const markOrderAsConversationalSuccessResponse = await analyticsOrderStateContainer.markOrderAsSuccessfulConversation(applicationId, sessionId);
        if (markOrderAsConversationalSuccessResponse.resultType === "Ok") {
            setConversationalSuccess("successful");
            // store state in local storage
            const analyticsOrderState = analyticsOrderStateContainer.getStoredAnalyticsOrderState();
            if (analyticsOrderState[sessionId]) {
                analyticsOrderState[sessionId].successfulConversation = true;
            } else {
                analyticsOrderState[sessionId] = { successfulConversation: true };
            }
            analyticsOrderStateContainer.storeAnalyticsOrderState(analyticsOrderState)
        }
        toggleConversationalSuccessConfirmationModal();
    }

    return (
        <RelativeWrapper
            key={props?.events[0]?.sessionId}
            style={{ height: props.manualHeight ? `${props.manualHeight}px` : "500px" }}
            className={`${props.inModal ? ' in-modal' : ""}`}
        >
            {showOrderPlacedConfirmationModal &&
                <ConfirmationDialog
                    title={`Mark Order as Placed`}
                    deleteText="Yes, Mark Order as Placed"
                    onClose={toggleOrderPlacedConfirmationModal}
                    onConfirm={() => markAsOrderPlaced()}
                    warning={true}
                >
                    <WarningConfirmation
                        text={`Are you sure you want to mark this order as placed?`}
                        useWarningColor={true}
                    />
                </ConfirmationDialog>
            }
            {showConversationalSuccessConfirmationModal &&
                <ConfirmationDialog
                    title={`Mark As Successful Conversation`}
                    deleteText="Yes, Mark As Successful"
                    onClose={toggleConversationalSuccessConfirmationModal}
                    onConfirm={() => markOrderAsConversationalSuccess()}
                    warning={true}
                >
                    <WarningConfirmation
                        text={`Are you sure you want to mark this order as a successful conversation?`}
                        useWarningColor={true}
                    />
                </ConfirmationDialog>
            }
            <HistoryBody
                className={`chats-history-scrollable-body${props.hideBorder ? ' hide-border' : ""}${props.inModal ? ' in-modal' : ""}`}
                autoHide
                autoHeight
                autoHeightMax={`${isMobile ? `calc(100vh - 200px)` : `calc(100vh - 250px)`}`}
            >
                <AllConversationsContainer className={`chats-conversations-container scrollable`}>
                    <TopLevelButtonsContainer>
                        {(orderState && !sessionContainer.simpleView) &&
                            <TooltipWrapper text={orderState === "orderPlaced" ? "This order has been marked as placed and cannot be updated." : "Click here to mark this order as placed."} place="right">
                                <div>
                                    <StyledButton
                                        loading={analyticsOrderStateContainer.isLoading || sessionContainer.loadingEvents}
                                        disabled={orderState === "orderPlaced" ? true : false}
                                        themes={['secondary-small']}
                                        text={orderState === "orderPlaced" ? "Order Placed" : "Order Not Placed"}
                                        onClick={() => toggleOrderPlacedConfirmationModal()}
                                        type="button"
                                        rightIcon={orderState === "orderPlaced" ? greenCheckIcon : redXIcon}
                                    />
                                </div>
                            </TooltipWrapper>
                        }
                        {(conversationalSuccess && !sessionContainer.simpleView) &&
                            <TooltipWrapper text={conversationalSuccess === "successful" ? "This order has been marked as a successful conversation and cannot be updated." : "Click here to mark this order as a successful conversation."} place="right">
                                <div>
                                    <StyledButton
                                        loading={analyticsOrderStateContainer.isLoading || sessionContainer.loadingEvents}
                                        disabled={conversationalSuccess === "successful" ? true : false}
                                        themes={['secondary-small']}
                                        text={conversationalSuccess === "successful" ? "Successful Convo" : "Unsuccessful Convo"}
                                        onClick={() => toggleConversationalSuccessConfirmationModal()}
                                        type="button"
                                        rightIcon={conversationalSuccess === "successful" ? greenCheckIcon : redXIcon}
                                    />
                                </div>
                            </TooltipWrapper>
                        }
                        {
                            recordingsAvailable ?
                                clickedRecordings ?
                                    <RecordingButtonContainer>
                                        <StyledButton
                                            disabled={false}
                                            themes={['secondary-small']}
                                            text="Unprocessed"
                                            onClick={() => {
                                                loadRecording(false);
                                                if (props?.resetCopyButtonText)
                                                    props?.resetCopyButtonText();
                                            }}
                                            type="button"
                                        />
                                        <ProcessedButton
                                            disabled={false}
                                            themes={['secondary-small']}
                                            text="Processed"
                                            onClick={() => {
                                                loadRecording(true);
                                                if (props?.resetCopyButtonText)
                                                    props?.resetCopyButtonText();
                                            }}
                                            type="button"
                                        />
                                    </RecordingButtonContainer>
                                    :
                                    <StyledButton
                                        disabled={false}
                                        themes={['secondary-small']}
                                        text="Recordings"
                                        onClick={() => setClickedRecordings(true)}
                                        type="button"
                                    />
                                :
                                <></>
                        }
                        {
                            !props?.simple &&
                            <StyledButton
                                disabled={false}
                                themes={['secondary-small']}
                                text="All Events JSON"
                                onClick={() => {
                                    props.setEventsToShow(props.events);
                                    if (props.resetCopyButtonText)
                                        props.resetCopyButtonText();
                                }}
                                type="button"
                            />
                        }
                    </TopLevelButtonsContainer>
                    {Object.keys(groupedEvents).map((requestId, idx) => {
                        return renderRequestEventBubbles(requestId, groupedEvents[requestId], idx)
                    })}
                    {
                        callComplete &&
                        <LiveChatBubble markdown={generateCallCompleteMarkdown(callComplete)} sender={"bot"} />
                    }
                </AllConversationsContainer>
                {props.inModal ? <AlwaysScrollToBottom /> : <></>}
            </HistoryBody>
            <PageError errors={sessionContainer.error?.length ? [sessionContainer.error] : null} />
            <PageError errors={analyticsOrderStateContainer.errors?.length ? analyticsOrderStateContainer.errors : null} />
        </RelativeWrapper>
    )
};

const RequestDetails = styled.div``;

const RelativeWrapper = styled.div`
    background-color: ${color_shades_lighter};
    &.in-modal {
        padding-top: 16px;
    }
`;

const HistoryBody = styled(CustomScrollbars)`
    color: ${color_shades_darkest};
    background-color: ${color_shades_lighter};
    margin-top: 16px;
    margin-bottom: 16px;
    &.in-modal {
        margin-top: 0px;
        margin-bottom: 0px;    
    }
    ${breakpoint_small} {
        width: 100%;
    }
`;

const AllConversationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 16px 16px 16px;
    overflow-wrap: break-word;
    word-break:break-word;
    &.scrollable {
        padding: 0px 16px 16px 16px;
    }
`;

const HiddenDiv = styled.div`
    display: none;
`;

const TopLevelButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
`;

const RecordingButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    right: 180px;
`;

const StyledButton = styled(Button)`
    width: 186px;
    margin-right: 8px;
    margin-left: 8px;
`;

const ProcessedButton = styled(StyledButton)`
    margin-top: 0px;
`;

export default SessionReview;