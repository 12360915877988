import { sendGet } from './generic';
import IResult from '../../models/result/IResult';
import { getAnalyticsApiUrl } from '../../constants/Urls';
import CallStatistics from '../../models/customAssistant/CallStatistics';
import TopIntents from '../../models/customAssistant/TopIntents';
import OrderPlaced from '../../models/analytics/OrderPlacedEvent';
import ConversationalSuccess from '../../models/analytics/ConversationalSuccessEvent';
import ItemAddedToOrder from '../../models/analytics/ItemAddedToOrderEvent';
import OrderTotalStatistics from '../../models/analytics/OrderTotalStatisticsModel';
import TopLocationWithOrderCountCollection from '../../models/analytics/LocationWithOrderCount';
import AnalyticsRequestFilter from '../../models/analytics/AnalyticsRequestFilter';
import CallTransferEvents from '../../models/analytics/CallTransferEvents';
import ImmediateCallTransferEvents from '../../models/analytics/ImmediateCallTransferEvents';
import OrderCompletionBreakDownPerAppCollection from '../../models/analytics/OrderCompletionBreakDownPerAppCollection';
import ConversationalSuccessBreakdownCollection from '../../models/analytics/ConversationalSuccessBreakdownCollection';
import CallTransferredRateModelCollection from '../../models/analytics/CallTransferredRateModelCollection';
import CallImmediateTransferredRateModelCollection from '../../models/analytics/CallImmediateTransferredRateModelCollection';
import AverageOrderTotalModelCollection from '../../models/analytics/AverageOrderTotalModelCollection';
import SessionDateModel from '../../models/analytics/api/SessionDateModel';


export const getCallStatistics = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<CallStatistics>> => {
    return sendGet<CallStatistics>(`/v2/Organization/${organizationId}/callStatistics?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}

export const getCallTransferredEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<CallTransferEvents[]>> => {
    const res = sendGet<CallTransferEvents[]>(`/v2/Organization/${organizationId}/callTransferred?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
    return res
}

export const getImmedirateCallTransferredEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ImmediateCallTransferEvents[]>> => {
    return sendGet<ImmediateCallTransferEvents[]>(`/v2/Organization/${organizationId}/immediateTransfers?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}

export const getTopIntents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<TopIntents[]>> => {
    return sendGet<TopIntents[]>(`/v2/Organization/${organizationId}/topIntents?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}

export const getOrderPlacedEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OrderPlaced[]>> => {
    return sendGet<OrderPlaced[]>(`/v2/Organization/${organizationId}/orderPlaced?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getOrgCallCompleteEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<any[]>> => {
    return sendGet<OrderPlaced[]>(`/v2/Organization/${organizationId}/callComplete?${buildDateFilter(filter)}`, getAnalyticsApiUrl());}

export const getConversationalSuccessEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ConversationalSuccess[]>> => {
    return sendGet<ConversationalSuccess[]>(`/v2/Organization/${organizationId}/conversationalSuccess?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getItemAddedToOrderEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ItemAddedToOrder[]>> => {
    return sendGet<ItemAddedToOrder[]>(`/v2/Organization/${organizationId}/itemAddedToOrder?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getOrderTotalStatistics = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OrderTotalStatistics>> => {
    return sendGet<OrderTotalStatistics>(`/v2/Organization/${organizationId}/orderTotals?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getOrderCompletionBreakDownPerApp = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<OrderCompletionBreakDownPerAppCollection>> => {
    return sendGet<OrderCompletionBreakDownPerAppCollection>(`/v2/Organization/${organizationId}/orderCompletionBreakDownPerApp?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getConversationalSuccessBreakdownPerApp = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ConversationalSuccessBreakdownCollection>> => {
    return sendGet<ConversationalSuccessBreakdownCollection>(`/v2/Organization/${organizationId}/conversationalSuccessBreakdownPerApp?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getCallTransferredBreakdownPerApp = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<CallTransferredRateModelCollection>> => {
    return sendGet<CallTransferredRateModelCollection>(`/v2/Organization/${organizationId}/callTransferredBreakdownPerApp?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getCallImmediateTransferredBreakdownPerApp = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<CallImmediateTransferredRateModelCollection>> => {
    return sendGet<CallImmediateTransferredRateModelCollection>(`/v2/Organization/${organizationId}/callImmediateTransferredBreakdownPerApp?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getAverageOrderTotalBreakdownPerApp = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<AverageOrderTotalModelCollection>> => {
    return sendGet<AverageOrderTotalModelCollection>(`/v2/Organization/${organizationId}/averageOrderTotalBreakdownPerApp?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}
export const getTopLocationsWithNumberOfOrdersPlaced = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<TopLocationWithOrderCountCollection>> => {
    return sendGet<TopLocationWithOrderCountCollection>(`/v2/Organization/${organizationId}/topLocationsWithNumberOfOrdersPlaced?${buildDateFilter(filter)}&top=${filter.count}`, getAnalyticsApiUrl());
}
export const getItemAddedConversationalSuccessEvents = (organizationId: string, filter: AnalyticsRequestFilter): Promise<IResult<ConversationalSuccess[]>> => {
    return sendGet<ConversationalSuccess[]>(`/v2/Organization/${organizationId}/itemAddedToOrderConversationalSuccessEvents?${buildDateFilter(filter)}`, getAnalyticsApiUrl());
}

//Take indicates the number of sessions to be returned.
export const getSessionsByOrganizationId = (organizationId: string, filter: AnalyticsRequestFilter, skip: number = 0, take: number = -1): Promise<IResult<SessionDateModel[]>> => {
    return sendGet<SessionDateModel[]>(`/v2/Organization/${organizationId}/sessions?${buildDateFilter(filter)}&skip=${skip}&take=${take}`, getAnalyticsApiUrl());
}

const buildDateFilter = (filter: AnalyticsRequestFilter) => {
    let query = `startDate=${filter.startDate}&endDate=${filter.endDate}`;
    return query;
}