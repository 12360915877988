import React, { useEffect, useState } from 'react';
import { Subscribe } from 'unstated';
import ApplicationStatisticsContainer from '../../state/containers/ApplicationStatisticsContainer';
import TabContainerPanel from '../../components/structure/Panels/TabContainerPanel';
import { TabPanel } from 'react-tabs';
import AnalyticsInterval from '../../models/analytics/AnalyticsInterval';
import moment from 'moment';
import ApplicationMeasurementPage from './components/ApplicationMeasurementPage';
import ApplicationUserAnalyticsContainer from '../../state/containers/ApplicationUserAnalyticsContainer';
import ApplicationInteractionAnalyticsContainer from '../../state/containers/ApplicationInteractionAnalyticsContainer';
import { css } from '@emotion/css';
import DateRangeIntervalPicker from '../../components/analytics/DateRangeIntervalPicker';
import TimeIntervalSelectorHeader from '../../components/analytics/TimeIntervalSelectorHeader';
import { color_shades_dark } from '../../constants/colors';
import ScrollablePanelBody from '../../components/structure/ScrollablePanelBody';
import FeatureContainer from '../../state/containers/FeatureContainer';
import ApplicationMissAnalyticsContainer from '../../state/containers/ApplicationMissAnalyticsContainer';
import { DATE_FORMAT } from '../../models/analytics/AnalyticsDateFormat';
import FilterToggleButton from '../../components/analytics/filters/FilterToggleButton';
import AnalyticsFilters from '../../components/analytics/filters/AnalyticsFilters';
import AnalyticsFilterState from '../../models/analytics/AnalyticsFilterState';
import ApplicationModel from '../../models/applications/api/ApplicationModel';
import ApplicationAnalyticsFacetContainer from '../../state/containers/ApplicationAnalyticsFacetContainer';
import ApplicationTrackingContainer from '../../state/containers/ApplicationTrackingContainer';
import ApplicationTrackingPage from './components/ApplicationTrackingPage';
import qs from 'query-string';
import Button from '../../components/general/Button';
import styled from '@emotion/styled';
import ApplicationEnvironmentsContainer from '../../state/containers/ApplicationEnvironmentsContainer';
import ApplicationContainer from '../../state/containers/ApplicationContainer';
import AnalyticsSettingsModal from '../applicationAppliedTemplateManager/components/AnalyticsSettingsModal';
import SsmlContainer from '../../state/containers/SsmlContainer';
import UserApplicationPreferencesContainer from '../../state/containers/UserApplicationPreferencesContainer';
import SparkContainer from '../../hooks/SparkContainer';
import { breakpoint_small } from '../../constants/breakpoints';

const measureIcon = require('../../content/images/analytics-icons/measure-icon.svg');
const measureIconBlue = require('../../content/images/analytics-icons/measure-icon-blue.svg');
const trackIcon = require('../../content/images/analytics-icons/track-icon.svg');
const trackIconBlue = require('../../content/images/analytics-icons/track-icon-blue.svg');
const settingsIcon = require('../../content/images/settings-dark.svg');

interface ApplicationAnalyticsProps {
    application: ApplicationModel
    facetContainer: ApplicationAnalyticsFacetContainer
    history: any
}

const ApplicationAnalytics: React.FC<ApplicationAnalyticsProps> = (props) => {

    const query = qs.parse(window.location.search);

    const [interval, setInterval] = useState<AnalyticsInterval>(query?.interval as string ?? 'past 30 days');
    const [startDate, setStartDate] = useState<string>(query?.startDate as string ?? moment().startOf('day').subtract(28, 'days').format(DATE_FORMAT));
    const [endDate, setEndDate] = useState<string>(query?.endDate as string ?? moment().endOf('day').add(2, 'days').format(DATE_FORMAT));
    const [isDatePickerExpanded, setIsDatePickerExpanded] = useState<boolean>(false);
    const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);
    const [filter, setFilter] = useState<AnalyticsFilterState>({});
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

    const sparkContainer = SparkContainer.useContainer();

    useEffect(() => {
        if (!props.application?.id?.length) return;
        props.facetContainer?.getDeviceTargets();
        props.facetContainer?.getAssistants(props.application?.id, startDate, endDate);
    }, [props.facetContainer, props.application, startDate, endDate]);

    const handleTabSelection = (index: number, last: number) => {
        if (index == last) return;
        const urlStart = `/v/apps/${props.application?.id}/analytics/`;

        if (index == 0) {
            props.history.push(urlStart + window.location.search)
        }
        if (index == 1) {
            props.history.push(urlStart + "track" + window.location.search)
        }
    };

    const getSelectedIndex = () => {
        if (location.href.indexOf("track") > -1) {
            return 1;
        }
        return 0;
    };

    const handleDateRangeChange = (startDate: string, endDate: string, interval: AnalyticsInterval) => {

        setStartDate(startDate);
        setEndDate(endDate);
        setInterval(interval);
        setIsDatePickerExpanded(false);
        setFilter({
            ...filter
        });

        props.facetContainer?.getAssistants(props.application?.id, startDate, endDate);

        props.history.push({
            search: `?startDate=${startDate}&endDate=${endDate}&interval=${interval}`
        });

    };

    const toggleDatePicker = () => {
        setIsDatePickerExpanded(!isDatePickerExpanded);
    };

    const toggleFilter = () => {
        setIsFilterExpanded(!isFilterExpanded);
    };

    const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };

    const handleFilterChange = (filter: AnalyticsFilterState) => {
        setFilter({
            ...filter,
            assistants: filter.assistants ? [...filter.assistants] : [],
            interfaces: filter.interfaces ? [...filter.interfaces] : [],
            deviceTargetIds: filter.deviceTargetIds ? [...filter.deviceTargetIds] : [],
            languageIds: filter.languageIds ? [...filter.languageIds] : [],
            locales: filter.locales ? [...filter.locales] : [],
        });
    };

    const getFilterCount = () => {
        let count = 0;
        if (filter?.assistants?.length > 0) {
            count++;
        }
        if (filter?.interfaces?.length > 0) {
            count++;
        }
        if (filter?.deviceTargetIds?.length > 0) {
            count++;
        }
        if (filter?.languageIds?.length > 0) {
            count++;
        }
        return count;
    };

    const currentFilter = props.facetContainer.convertFilter(startDate, endDate, filter, props.facetContainer.state.deviceTargets ?? [])

    return (
        <Subscribe to={[ApplicationUserAnalyticsContainer,
            ApplicationInteractionAnalyticsContainer, ApplicationStatisticsContainer,
            ApplicationMissAnalyticsContainer, ApplicationTrackingContainer,
            ApplicationContainer, ApplicationEnvironmentsContainer,
            FeatureContainer, SsmlContainer, UserApplicationPreferencesContainer]}>
            {(userAnalyticsContainer: ApplicationUserAnalyticsContainer,
                interactionAnalyticsContainer: ApplicationInteractionAnalyticsContainer,
                statisticsContainer: ApplicationStatisticsContainer,
                missContainer: ApplicationMissAnalyticsContainer,
                trackingContainer: ApplicationTrackingContainer,
                applicationContainer: ApplicationContainer,
                environmentContainer: ApplicationEnvironmentsContainer,
                featureContainer: FeatureContainer,
                ssmlContainer: SsmlContainer,
                userAppPrefContainer: UserApplicationPreferencesContainer) => {
                return (
                    <>
                        <TabContainerPanel
                            className={containerStyle}
                            tabs={[{
                                title: 'Measure',
                                icon: measureIcon,
                                selectedIcon: measureIconBlue,
                                className: "ac-analytics-measure"
                            }, {
                                title: 'Track',
                                icon: trackIcon,
                                selectedIcon: trackIconBlue,
                                className: "ac-analytics-track"
                            }]}
                            selectedIndex={getSelectedIndex()}
                            onTabSelected={handleTabSelection}
                            additionalHeaderContent={() => <div className={headerContainer}>
                                <TimeIntervalSelectorHeader startDate={startDate} endDate={endDate} isExpanded={isDatePickerExpanded} onClick={toggleDatePicker} />
                                {!sparkContainer.inSpark &&
                                    <>
                                        <FilterToggleButton filterCount={getFilterCount()} isExpanded={isFilterExpanded} onClick={toggleFilter} />
                                        <Button
                                            themes={['white-small']}
                                            text="Settings"
                                            onClick={() => toggleSettings()}
                                            rightComponent={() => <ButtonRightImage><img src={settingsIcon} /></ButtonRightImage>}
                                        />
                                    </>
                                }
                            </div>}>
                            <TabPanel>
                                <ScrollablePanelBody>
                                    {isFilterExpanded &&
                                        <AnalyticsFilters currentFilter={currentFilter}
                                            filterCount={getFilterCount()}
                                            onChange={handleFilterChange}
                                            languages={props.application?.languages ?? []}
                                            deviceTargets={props.facetContainer.state.deviceTargets ?? []}
                                            assistants={props.facetContainer.state.assistants ?? []}
                                            interfaces={props.facetContainer.state.interfaces ?? []} />}
                                    {isDatePickerExpanded &&
                                        <DateRangeIntervalPicker startDate={startDate}
                                            endDate={endDate}
                                            interval={interval}
                                            onChange={handleDateRangeChange} />}
                                    <ApplicationMeasurementPage
                                        application={props.application}
                                        ssmlContainer={ssmlContainer}
                                        userApplicationPreferencesContainer={userAppPrefContainer}
                                        interval={interval}
                                        userAnalyticsContainer={userAnalyticsContainer}
                                        interactionAnalyticsContainer={interactionAnalyticsContainer}
                                        missAnalyticsContainer={missContainer}
                                        statisticsContainer={statisticsContainer}
                                        featureContainer={featureContainer}
                                        envContainer={environmentContainer}
                                        applicationId={props.application?.id}
                                        filter={currentFilter}
                                        history={props.history}
                                        appsContainer={applicationContainer}
                                    />
                                </ScrollablePanelBody>
                            </TabPanel>
                            <TabPanel>
                                <ScrollablePanelBody>
                                    {isFilterExpanded &&
                                        <AnalyticsFilters currentFilter={currentFilter}
                                            onChange={handleFilterChange}
                                            languages={props.application?.languages ?? []}
                                            filterCount={getFilterCount()}
                                            deviceTargets={props.facetContainer.state.deviceTargets ?? []}
                                            assistants={props.facetContainer.state.assistants ?? []}
                                            interfaces={props.facetContainer.state.interfaces ?? []} />}
                                    {isDatePickerExpanded &&
                                        <DateRangeIntervalPicker startDate={startDate}
                                            endDate={endDate}
                                            interval={interval}
                                            onChange={handleDateRangeChange} />}
                                    <ApplicationTrackingPage
                                        trackingContainer={trackingContainer}
                                        applicationId={props.application?.id}
                                        filter={currentFilter}
                                        history={props.history} />
                                </ScrollablePanelBody>
                            </TabPanel>
                        </TabContainerPanel>
                        {
                            isSettingsOpen &&
                            <AnalyticsSettingsModal
                                applicationContainer={applicationContainer}
                                envContainer={environmentContainer}
                                appId={props.application.id}
                                onClose={() => toggleSettings()} />
                        }
                    </>
                )
            }}
        </Subscribe>
    )
}

const containerStyle = css`
    .panel-tab-list {
        flex: 0;
    }
    ${breakpoint_small} {
        .panel-tab-list {
            display: none;
        }
        .time-label {
            display: none;
        }
        .time-selection {
            border-left: none;
            font-size: 16px;
        }
    }
`;

const headerContainer = css`
    display: flex;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    margin-left: 94px;
    margin-right: 32px;
    align-items: center;
    border-left: 1px solid ${color_shades_dark};
    ${breakpoint_small} {
        margin-left: 0;
        margin-right: 0;
        border-left: none;
        justify-content: center;
    }
`

const ButtonRightImage = styled.div`    
    margin-left: 8px;
    width: 20px;
    img {
        width: 16px;
        height: 16px;
    }
`

export default ApplicationAnalytics;