import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import * as yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import TemplateFormSectionModel from '../../../../shared/models/templating/api/TemplateFormSectionModel';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import TemplateFormFieldValueModel from '../../../../shared/models/templating/api/TemplateFormFieldValueModel';
import AppliedApplicationTemplateModel from '../../../../shared/models/templating/api/AppliedApplicationTemplateModel';
import TemplateFormFieldModel from '../../../../shared/models/templating/api/TemplateFormFieldModel';
import Loader from '../../../../shared/components/general/Loader';
import PanelHeaderContainer from '../../../../shared/components/structure/Panels/PanelHeaderContainer';
import Button from '../../../../shared/components/general/Button';
import AppliedApplicationTemplateSectionForm from './AppliedApplicationTemplateSectionForm';
import styled from '@emotion/styled';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';

const instructionsIcon = require('../../../../shared/content/images/info_icon.svg');

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    templateSection: TemplateFormSectionModel
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    appliedAppValues: TemplateFormFieldValueModel[]
    appliedAppTemplate: AppliedApplicationTemplateModel
    onNext: () => void
    onPrevious: () => void
    insertDynamicFormSection: (section: TemplateFormSectionModel) => void
    history: any
    currentSectionIndex?: number
    importedFormFields: TemplateFormFieldValueModel[]
    setImportedFormFields: (fields: TemplateFormFieldValueModel[]) => void
    calculateIncompleteSections: (templateFormFieldValues?: TemplateFormFieldValueModel[]) => void
}

interface AppliedAppTemplatesManagerFormData {
    templateFormValues: TemplateFormFieldValueModel[]
}

const InnerForm: React.FC<AppliedAppTemplatesManagerProps & FormikProps<AppliedAppTemplatesManagerFormData>> = (props) => {
    const [recentlyAddedModuleFormFields, setRecentlyAddedModuleFormFields] = useState<TemplateFormFieldModel[]>();

    const requiredFields = props.templateSection?.templateFormFields?.filter(f => f.isRequired) ?? [];
    const combinedRequiredFields = [...requiredFields, ...(props.templateSection?.dynamicFormFields?.filter(f => f.isRequired) ?? [])];
    const completeFields = combinedRequiredFields.filter(f => props.values.templateFormValues?.some(v => (v.dynamicFormFieldName == f.name && v.value) || (v.templateFormFieldId == f.id && v.value)));

    useEffect(() => {
        const allSections = props.appliedAppTemplate?.templateForm?.templateFormSections ?? [];
        const allFields = allSections.flatMap(s => s.templateFormFields);
        if (props.importedFormFields.length > 0) {
            for (const templateFormField of props.importedFormFields) {
                const matchingFormField = allFields?.find(formField => formField.title === templateFormField.title);
                if (matchingFormField) {
                    onDynamicFormValueChanged(matchingFormField, templateFormField.value);
                };
            };
        };
        props.calculateIncompleteSections(props.importedFormFields && props.importedFormFields);
    }, [props.importedFormFields]);

    useEffect(() => {
        const recentlyAddedFields = props.templateSection?.templateFormFields?.filter(f => f.recentlyAdded) ?? [];
        for (const templateFormSection of props.appliedAppTemplate?.templateForm?.templateFormSections ?? []) {
            for (const templateFormField of templateFormSection?.templateFormFields) {
                if (!props.appliedAppTemplate?.templateFormFieldValues.find(templateFormFieldValue => templateFormFieldValue.title === templateFormField.title)) {
                    if (templateFormField.defaultValue) {
                        recentlyAddedFields.push({ ...templateFormField, recentlyAdded: true });
                    }
                }
            }
        }
        setRecentlyAddedModuleFormFields(recentlyAddedFields);
    }, []);

    useEffect(() => {
        if (props.appliedAppValues.length === 0) return;
        const updatedFieldValues = [...props.appliedAppValues];
        for (const templateFormSection of props.appliedAppTemplate?.templateForm?.templateFormSections ?? []) {
            for (const templateFormField of templateFormSection?.templateFormFields) {
                if (!props.appliedAppTemplate?.templateFormFieldValues.find(templateFormFieldValue => templateFormFieldValue.title === templateFormField.title)) {
                    updatedFieldValues.push({
                        value: templateFormField.defaultValue,
                        fieldType: templateFormField.fieldType,
                        tip: templateFormField.tip,
                        title: templateFormField.title,
                        label: templateFormField.label,
                        defaultValue: templateFormField.defaultValue,
                        associatedVariable: templateFormField.associatedVariable,
                        placeholder: templateFormField.placeholder,
                        templateFormFieldId: templateFormField.id
                    });
                }
            }
        }
        props.setFieldValue("templateFormValues", updatedFieldValues);
    }, [props.appliedAppValues]);

    const onDynamicFormValueChanged = (field: TemplateFormFieldModel, value: string) => {
        const templateValues = [...props.values.templateFormValues] ?? [];
        // if we have the value, update it
        const existingField = templateValues.find(v => (field.name && v.dynamicFormFieldName == field.name) || (field.title && v.title == field.title))
        if (existingField != null) {
            const index = templateValues.findIndex(v => v.title == existingField.title);
            existingField.value = value;
            templateValues[index] = existingField;
        }
        // if we don't, add it
        else {
            const fieldValue: TemplateFormFieldValueModel = {
                value: value,
                fieldType: field.fieldType,
                tip: field.tip,
                title: field.title,
                label: field.label,
                defaultValue: field.defaultValue,
                associatedVariable: field.associatedVariable,
                placeholder: field.placeholder
            };
            if (field.name)
                fieldValue.dynamicFormFieldName = field.name;
            else
                fieldValue.templateFormFieldId = field.id;
            templateValues.push(fieldValue);
        }
        props.setFieldValue("templateFormValues", templateValues);
        props.calculateIncompleteSections(templateValues);
    }

    const isLoading = props.appliedAppTemplateContainer.state.isLoading || props.appliedAppTemplateContainer.state.isSaving;

    return (
        <>
            {
                props.appliedAppTemplateContainer.state.isLoading ?
                    <Loader /> :
                    <form onSubmit={props.handleSubmit}>
                        <PanelHeaderContainer>
                            <InstructionsIcon src={instructionsIcon} />
                            <InstructionsText>Complete the fields below</InstructionsText>
                            <div className={buttonContainerStyle}>
                                {props.currentSectionIndex != 0 &&
                                    <Button
                                        disabled={props.appliedAppTemplateContainer.state.isLoading}
                                        themes={["white-small"]}
                                        text="Previous"
                                        type="button"
                                        onClick={props.onPrevious}
                                    />
                                }
                                <Button
                                    disabled={((props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl != null) && (combinedRequiredFields.length != completeFields.length))}
                                    loading={isLoading}
                                    themes={["primary-small"]}
                                    text={props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl ? "Next" : "Save & Next"}
                                    type="button"
                                    onClick={() => { props.submitForm() }}
                                />
                            </div>
                        </PanelHeaderContainer>
                        {props.templateSection &&
                            <AppliedApplicationTemplateSectionForm
                                onPrevious={props.onPrevious}
                                submitForm={props.submitForm}
                                combinedRequiredFields={combinedRequiredFields}
                                completeFields={completeFields}
                                appliedAppTemplateContainer={props.appliedAppTemplateContainer}
                                appliedAppTemplate={props.appliedAppTemplate}
                                key={props.templateSection?.name ?? props.templateSection?.id}
                                section={props.templateSection}
                                values={props.values.templateFormValues}
                                applicationId={props.applicationId}
                                onFieldValueChange={onDynamicFormValueChanged}
                                recentlyAddedModuleFormFields={recentlyAddedModuleFormFields}
                            />
                        }
                    </form>
            }
        </>
    )
}

const InstructionsIcon = styled.img`
    width: 24px;
    height: 24px;
    margin: 0 8px 0 32px;
    ${breakpoint_small} {
        display: none;
    }
`;

const InstructionsText = styled.p`
    ${breakpoint_small} {
        display: none;
    }
`;

const buttonContainerStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
    ${breakpoint_small} {
        margin-right: 0;
    }
`;

const UpdateAppliedAppTemplateForm = withFormik<AppliedAppTemplatesManagerProps, AppliedAppTemplatesManagerFormData>({
    mapPropsToValues: props => {
        return {
            templateFormValues: [...(props.appliedAppValues ?? [])]
        }
    },
    validationSchema: yup.object().shape({
    }),
    handleSubmit: async (values, { props, setFieldError }) => {
        if (props.appliedAppTemplate?.templateForm?.templateConfiguration?.dynamicFormUrl) {
            const result = await props.appliedAppTemplateContainer.finishFormSection(props.appliedAppTemplate?.id, {
                fieldValues: values.templateFormValues
            });
            if (result.resultType == "Ok") {
                const dynamicResponse = result.data;
                switch (dynamicResponse.responseType) {
                    case ("Invalid"):
                        if ((dynamicResponse.validation?.length ?? 0) > 0) {
                            //if there are errors then set the field errors and don't go nextsince we want to stay on this section.
                            dynamicResponse.validation.forEach(v => {
                                setFieldError(`fields.${v.dynamicFormFieldName ?? v.templateFormFieldId}`, v.message);
                            });
                        }
                        break;
                    case ("Custom"):
                        if (dynamicResponse.formSection != null) {
                            await props.insertDynamicFormSection(dynamicResponse.formSection);
                        }
                        break;
                    case ("Oauth"):
                        if (dynamicResponse.oauthUrl != null) {
                        }
                    case ("Continue"):
                    default:
                        setTimeout(props.onNext, 1); // we want to elevate the transition to the main thread
                        break;
                }
            }
        }
        else {
            props.appliedAppTemplateContainer.updateAppliedTemplate(props.appliedAppTemplate?.id, {
                templateFormFieldValues: values.templateFormValues,
                name: props.appliedAppTemplate.name
            }).then(result => {
                if (result.resultType == "Ok")
                    setTimeout(props.onNext, 1); // we want to elevate the transition to the main thread
            })
        }
    }
})(InnerForm);


export default UpdateAppliedAppTemplateForm;