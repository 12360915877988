import React, { useState } from 'react'
import Button from '../../../components/general/Button';
import styled from '@emotion/styled';
import { color_colors_ocean, color_variants_ocean_light_2 } from '../../../constants/colors';
import TextField from '../../../components/forms/TextField';
import { css } from '@emotion/css';
import StyledDropdown from '../../applicationSettings/components/customAssistantConfigurations/StyledDropdown';
import ContentCollapsingSection from '../../../components/structure/ContentCollapsingSection';
import CustomScrollbars from '../../../components/structure/CustomScrollbars';
import MenuContainer from '../../../hooks/MenuContainer';
import Loader from '../../../components/general/Loader';
import { style_border_default } from '../../../constants/stylesValues';
import ConfirmationDialog from '../../../components/structure/ConfirmationDialog';
import WarningConfirmation from '../../../components/general/WarningConfirmation';
import ContentCard, { NoMarginContentCard } from '../../../components/structure/ContentCard';
import SynonymsEditor from '../../interactionModel/components/SynonymsEditor';
import CollapsableItem from './CollapsableItem';
import PanelTitle from '../../contentPage/components/PanelTitle';
import Scrollbars from 'react-custom-scrollbars';
import { MenuItemOptionGroup } from '../../../models/nlp/menu/MenuItemOptionGroup';
import Switch from '../../../components/forms/Switch';
import { getOptionGroupName } from './MenuItemSizeEditor';
import CheckboxField from '../../../components/forms/CheckboxField';
import PersistentPropertyTitle from './PersistentPropertyTitle';

const listIcon = require("../../../content/images/menu-editor/list.svg");
const menuItemIcon = require("../../../content/images/menu-editor/restaurant-menu.svg");
const synonymsIcon = require('../../../content/images/synonyms.svg');

interface MenuItemEditorProps {
    history: any
    rightPanelRef: React.RefObject<Scrollbars>
}

export const getExtraOptionGroupText = (prompted: boolean, optionGroups: MenuItemOptionGroup[]) => {
    if (optionGroups.some(og => og.isDisabled)) {
        return "";
    }
    if (optionGroups.some(og => og.promptIfNoSelections || og.numberOfRequiredOptions)) {
        if (prompted) {
            return "Assistant will ask for selection"
        }
        return "";
    } else {
        return "Assistant will not ask for selection";
    }
}

export default function MenuItemEditor(props: MenuItemEditorProps) {
    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
    const menuContainer = MenuContainer.useContainer();

    const handleCategoryOnChange = (optionValue: string, value: string) => {
        if (!menuContainer.menuItem.attributes) {
            menuContainer.menuItem.attributes = {
                data: {
                    category: value
                }
            }
        } else if (!menuContainer.menuItem.attributes?.data) {
            menuContainer.menuItem.attributes.data = { category: value };
        } else {
            menuContainer.menuItem.attributes.data.category = value;
        }
        menuContainer.updateMenuItem(menuContainer.menuItem, menuContainer.menuItem.attributes, "data.category");
    };

    //go back no check
    function goBackToMainMenu() {
        menuContainer.resetMenuItemSelections();
        menuContainer.setMenuItemAugmentStatus(null);
        props.history.goBack();
    }

    //go back if menu item not edited
    function handleGoBackToMainMenuCheck() {
        if (menuContainer.isMenuItemEdited) {
            setShowConfirmCloseModal(true);
        } else {
            goBackToMainMenu();
        }
    }

    const activeOptionGroups = menuContainer.menuItem?.optionGroups?.filter(optionGroup => !optionGroup.isDisabled) ?? [];
    const inactiveOptionGroups = menuContainer.menuItem?.optionGroups?.filter(optionGroup => optionGroup.isDisabled) ?? [];

    const isEditable = menuContainer.menuItemAugmentStatus && menuContainer.menuItemAugmentStatus !== "success" ? false : true

    function renderOptionGroups(optionGroups: MenuItemOptionGroup[]) {
        return optionGroups?.map((optionGroupObj, index) => {
            return (
                <div key={index}>
                    <OptionGroupWrapper
                        className={optionGroupObj.referenceId === menuContainer.activeEditId ? "active" : ""}
                        key={optionGroupObj.editId}
                        onClick={() => {
                            if (props.rightPanelRef) {
                                props.rightPanelRef.current.scrollToTop()
                            }
                            const path = [{
                                text: optionGroupObj.name,
                                pathLength: 0,
                                selectedOption: null,
                                selectedOptionGroup: optionGroupObj,
                                selectedSizeGroup: null,
                                selectedSize: null,
                            }];
                            menuContainer.setPath([...path])
                            menuContainer.setActiveEditId(optionGroupObj.referenceId)
                        }}
                    >
                        <ListItemLeftWrapper>
                            <CollapsableItem
                                text={getOptionGroupName(optionGroupObj)}
                                isDisabled={optionGroupObj.isDisabled}
                                italicText={getExtraOptionGroupText(!!(optionGroupObj.promptIfNoSelections || optionGroupObj.numberOfRequiredOptions), optionGroups)}
                                onEnabledChange={(enabled) => {
                                    optionGroupObj.isDisabled = !enabled;
                                    menuContainer.updateMenuItem(menuContainer.menuItem, optionGroupObj, "isDisabled");
                                }}
                            />
                        </ListItemLeftWrapper>
                    </OptionGroupWrapper>
                </div>
            )
        })
    }

    if (!menuContainer.menuItem) {
        return <Loader />
    }

    return (
        <>
            <MenuItemStyle>
                {showConfirmCloseModal &&
                    <ConfirmationDialog
                        title={`Confirm Close`}
                        deleteText="Yes, Close"
                        onClose={() => setShowConfirmCloseModal(!showConfirmCloseModal)}
                        onConfirm={goBackToMainMenu}>
                        <WarningConfirmation
                            text={`Are you sure you want to go back to the main menu? Any unsaved changes will be lost.`}
                        />
                    </ConfirmationDialog>
                }
                <HeaderWrapper>
                    <Button
                        themes={["primary-small"]}
                        text="Go Back To Main Menu"
                        type="button"
                        onClick={handleGoBackToMainMenuCheck} />
                </HeaderWrapper>
                <MenuItemHeader
                    className={!isEditable ? "bluredMenuItem" : ""}
                >
                    <PanelTitle
                        title={menuContainer.menuItem?.originalName}
                        subtitle={menuContainer.menuItem?.colloquialName ? `"${menuContainer.menuItem.colloquialName}"` : ""}
                        detail={`Update the ${menuContainer.menuItem?.originalName} menu item below.`}>
                    </PanelTitle>
                    <PersistentPropertyLabelWrapper>
                        <StyledEnabledSwitch
                            disabled={!isEditable}
                            checked={!menuContainer.menuItem.isDisabled}
                            onChange={() => {
                                const newMenuItem = {
                                    ...menuContainer.menuItem,
                                    isDisabled: !menuContainer.menuItem.isDisabled
                                }
                                menuContainer.updateMenuItem(newMenuItem, menuContainer.menuItem, "isDisabled");
                            }} detail={menuContainer.menuItem.isDisabled ? "Can't Order" : "Can Order"}
                        />
                        <PersistentPropertyTitle
                            title='Can Order'
                            showTitle={false}
                            item={menuContainer.menuItem}
                            propertyName='isDisabled'
                        />
                    </PersistentPropertyLabelWrapper>
                </MenuItemHeader>
                <MenuItemWrapper
                    className={!isEditable ? "bluredMenuItem" : ""}
                >
                    {!isEditable ? <EditControlPanel onClick={(e) => e.stopPropagation()} /> : <></>}
                    <CustomScrollbars
                        autoHide
                        autoHeight
                        autoHeightMax="calc(100vh - 284px)" >
                        <ContentCollapsingSection
                            className={firstContentCollapsingSectionStyle}
                            disabled={!isEditable}
                            title={"Details"}
                            isCollapsedByDefault={false}
                            icon={menuItemIcon}
                        >
                            <StyledContentCard
                                id="menuItemDetails" >
                                <TextField
                                    name="Assistant Refers to As"
                                    useForm={false}
                                    disabled={!isEditable}
                                    value={menuContainer.menuItem.colloquialName}
                                    label={
                                        <PersistentPropertyTitle
                                            title='Item Refered to As'
                                            item={menuContainer.menuItem}
                                            propertyName='colloquialName'
                                        />
                                    }
                                    tooltip='This is the name the assistant will use to refer to this item.'
                                    tooltipStyle={tooltipStyle}
                                    placeholder={`${`Enter a new name for ${menuContainer.menuItem.originalName} or leave blank to use the item's original name`}`}
                                    onChange={(e) => {
                                        menuContainer.menuItem.colloquialName = e.target.value;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, menuContainer.menuItem, "colloquialName");
                                    }}
                                    type="string"
                                />
                                <TextField
                                    name="Item Description"
                                    useForm={false}
                                    disabled={!isEditable}
                                    value={menuContainer.menuItem.attributes?.description ?? ""}
                                    label={
                                        <PersistentPropertyTitle
                                            title='Item Description'
                                            item={menuContainer.menuItem.attributes}
                                            propertyName='description'
                                        />
                                    }
                                    tooltip='This is the description the assistant will use to describe this item.'
                                    tooltipStyle={tooltipStyle}
                                    placeholder="Describe the item"
                                    onChange={(e) => {
                                        menuContainer.menuItem.attributes.description = e.target.value;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, menuContainer.menuItem.attributes, "description");
                                    }}
                                    type="string"
                                />

                                {Array.isArray(menuContainer.categories) ?
                                    <>
                                        <StyledDropdown
                                            disabled={!isEditable}
                                            value={menuContainer.menuItem.attributes?.data?.category ?? ""}
                                            handleOnChange={handleCategoryOnChange}
                                            fieldName="themeStyle"
                                            options={Array.isArray(menuContainer.categories) ?
                                                menuContainer.categories.map(category => {
                                                    if (category !== "All") return { label: category, value: category }
                                                }).filter(category => category)
                                                :
                                                []
                                            }
                                            size="medium"
                                            tooltip='This is the category the assistant will use to categorize this item.'
                                            label={
                                                <PersistentPropertyTitle
                                                    title='Category'
                                                    item={menuContainer.menuItem.attributes}
                                                    propertyName='data.category'
                                                />
                                            }
                                            placeholder="Select a category"
                                        />
                                    </>
                                    :
                                    <></>
                                }

                                <StyledCheckboxField
                                    checked={menuContainer.menuItem?.transferIfOrdered ?? false}
                                    onChange={(e) => {
                                        menuContainer.menuItem.transferIfOrdered = e.target.checked;
                                        menuContainer.updateMenuItem(menuContainer.menuItem, menuContainer.menuItem, "transferIfOrdered");
                                    }}
                                    label='Transfer Customer If Ordered'
                                    labelExtension={
                                        <PersistentPropertyTitle
                                            title='Transfer Customer If Ordered'
                                            showTitle={false}
                                            item={menuContainer.menuItem}
                                            propertyName='transferIfOrdered'
                                        />
                                    }
                                    tooltip='If checked, the customer will be transferred to the store when attempting to order this item.'
                                    tooltipStyle={transferIfOrderedToolTip}
                                />
                            </StyledContentCard>
                        </ContentCollapsingSection>
                        <GroupWrapper>
                            {!menuContainer.menuItem?.optionGroups?.length ? <></> :
                                <ContentCollapsingSection
                                    disabled={!isEditable}
                                    icon={listIcon}
                                    title={"Option Groups"}
                                    isCollapsedByDefault={true}
                                    className={collapsingSectionStyle}
                                >
                                    {activeOptionGroups?.length > 0 &&
                                        <ActiveOptionGroupsContentCard
                                            title="Edit Active Option Groups"
                                            subtitle='These option groups are currently active and available to customers.'
                                        >
                                            {renderOptionGroups(activeOptionGroups)}
                                        </ActiveOptionGroupsContentCard>
                                    }
                                    {inactiveOptionGroups?.length > 0 &&
                                        <InactiveOptionGroupsContentCard
                                            title={`Edit Inactive Option Groups`}
                                            subtitle="These option groups are currently inactive and unavailable to customers."
                                        >
                                            {renderOptionGroups(inactiveOptionGroups)}
                                        </InactiveOptionGroupsContentCard>
                                    }
                                </ContentCollapsingSection>
                            }
                        </GroupWrapper>
                        {!menuContainer.menuItem?.sizeGroups?.length ? <></> :
                            <GroupWrapper>
                                <ContentCollapsingSection
                                    disabled={!isEditable}
                                    icon={listIcon}
                                    title={"Size Groups"}
                                    isCollapsedByDefault={true}
                                >
                                    <ActiveSizeGroupsContentCard
                                        title="Edit Active Size Groups"
                                        subtitle="These size groups are currently active and available to customers."
                                    >
                                        {
                                            menuContainer.menuItem?.sizeGroups?.length > 0
                                                ?
                                                menuContainer.menuItem?.sizeGroups?.map((sizeGroupObj, index) => {
                                                    const sizeGroupArray = sizeGroupObj.sizes?.map(size => size?.name);
                                                    const sizeGroupList = sizeGroupArray.join(", ");
                                                    return (
                                                        <OptionGroupWrapper
                                                            className={menuContainer.path && menuContainer.path[menuContainer.path.length - 1]?.selectedSizeGroup ? "active" : ""}
                                                            key={`sizeGroup.${index}`}
                                                            onClick={() => {
                                                                const path = [{
                                                                    text: sizeGroupList.length > 20 ? sizeGroupList.substring(0, 20) + "..." : sizeGroupList,
                                                                    pathLength: 0,
                                                                    selectedOption: null,
                                                                    selectedOptionGroup: null,
                                                                    selectedSizeGroup: sizeGroupObj,
                                                                    selectedSize: null,
                                                                }];
                                                                menuContainer.setPath([...path])
                                                                menuContainer.setActiveEditId(null)
                                                            }}
                                                        >
                                                            <ListItemLeftWrapper>
                                                                <CollapsableItem
                                                                    text={sizeGroupArray.length > 0 ? sizeGroupList : sizeGroupObj.promptOverride.length > 20 ? sizeGroupObj.promptOverride.substring(0, 20) + "..." : sizeGroupObj.promptOverride}
                                                                    isDisabled={sizeGroupObj.isDisabled}
                                                                    onEnabledChange={(enabled) => {
                                                                        sizeGroupObj.isDisabled = !enabled;
                                                                        menuContainer.updateMenuItem(menuContainer.menuItem, sizeGroupObj, "isDisabled");
                                                                    }}
                                                                />
                                                            </ListItemLeftWrapper>
                                                        </OptionGroupWrapper>
                                                    )
                                                })
                                                :
                                                <NoContentAvailable>This item doesn't have any size groups.</NoContentAvailable>
                                        }
                                    </ActiveSizeGroupsContentCard>
                                </ContentCollapsingSection>
                            </GroupWrapper>
                        }
                        <ContentCollapsingSection
                            bottom
                            disabled={!isEditable}
                            title={"Synonyms"}
                            isCollapsedByDefault={true}
                            icon={synonymsIcon}
                            scrollDownOnClick={true}
                        >
                            <NoMarginContentCard
                                title="Add Synonyms"
                                subtitle={`Add other names or ways customers might refer to ${menuContainer.menuItem?.originalName ?? "this item"}.`}
                                id="optionSynonyms" >
                                <SynonymsEditor
                                    className={SynonymsClass}
                                    synonyms={menuContainer.menuItem?.additionalSynonyms}
                                    synonymsFieldName={'menuItemSynonyms'}
                                    disableFields={false}
                                    handleDelete={function (index: number, arrayHelpers: any) {
                                        let newSynonyms = menuContainer.menuItem?.additionalSynonyms?.filter((synonym, i) => i !== index);
                                        if (!newSynonyms?.length)
                                            newSynonyms = [];
                                        const newMenuItem = {
                                            ...menuContainer.menuItem,
                                            additionalSynonyms: newSynonyms
                                        }
                                        menuContainer.updateMenuItem(newMenuItem, menuContainer.menuItem, "additionalSynonyms");
                                    }}
                                    handleAddSynonym={function (synonym: string): void {
                                        const newMenuItem = {
                                            ...menuContainer.menuItem
                                        }
                                        if (!newMenuItem?.additionalSynonyms?.length) newMenuItem.additionalSynonyms = [];
                                        newMenuItem.additionalSynonyms.push(synonym);
                                        menuContainer.updateMenuItem(newMenuItem, menuContainer.menuItem, "additionalSynonyms");
                                    }}
                                    handleBlur={() => { }}
                                    autofocusNewSynonym={false}
                                    placeholder='Add a synonym'
                                />
                            </NoMarginContentCard>
                        </ContentCollapsingSection>
                    </CustomScrollbars>
                </MenuItemWrapper>
            </MenuItemStyle>
        </>
    )
}

const PersistentPropertyLabelWrapper = styled.div`
    display: flex;
    height: fit-content;
    align-items: flex-end;
    margin-right: 10px;
`;

const firstContentCollapsingSectionStyle = css`
    border-top: none;
`;

const ActiveSizeGroupsContentCard = styled(ContentCard)`
    margin-bottom: 32px;
`;

const InactiveOptionGroupsContentCard = styled(ContentCard)`
    margin-top: 32px;
    margin-bottom: 0px;
`;

const ActiveOptionGroupsContentCard = styled(ContentCard)`
    margin-bottom: 0px;
`;

const tooltipStyle = css`
    margin-left: 8px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: ${style_border_default};
`;

const MenuItemStyle = styled.div`
    width: 100%;
`;

const MenuItemWrapper = styled.div`
    height: 100%;
    border-top: ${style_border_default};
    &.bluredMenuItem {
        filter: blur(2px);
    }
`;

const NoContentAvailable = styled.div`
    padding: 16px 0;
`;

const StyledContentCard = styled(ContentCard)`
    margin: 0;
    padding-bottom: 32px;
`;

const ListItemLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const GroupWrapper = styled.div``;

const MenuItemHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.bluredMenuItem {
        filter: blur(2px);
    }
`;

const collapsingSectionStyle = css`
    box-shadow: 0px 2px 4px rgba(50, 70, 97, 0.1);
`;

const SynonymsClass = css`
    margin-top: 16px;
`;

const OptionGroupWrapper = styled.div`
    border: ${style_border_default};
    border-radius: 8px;
    margin: 8px 0px 8px 0px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &.active {
        border: 1px solid ${color_colors_ocean};
        box-shadow: 0px 0px 4px 4px ${color_variants_ocean_light_2};
    }
`;

const StyledEnabledSwitch = styled(Switch)`
    margin: 16px 0px auto auto;
`;

const EditControlPanel = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`;

const StyledCheckboxField = styled(CheckboxField)`
    margin-top: 24px;
`;

const transferIfOrderedToolTip = css`
    margin-top: 0px;
    margin-left: 8px;
`;