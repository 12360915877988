import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';
import * as yup from 'yup';
import { FormikProps, withFormik } from 'formik';
import ApplicationContainer from '../../../../shared/state/containers/ApplicationContainer';
import AppliedApplicationTemplateContainer, { MultipleTemplateSyncsStatus } from '../../../../shared/state/containers/AppliedApplicationTemplateContainer';
import TemplateFormSectionModel from '../../../../shared/models/templating/api/TemplateFormSectionModel';
import AppliedApplicationTemplateModel from '../../../../shared/models/templating/api/AppliedApplicationTemplateModel';
import TemplateFormFieldValueModel from '../../../../shared/models/templating/api/TemplateFormFieldValueModel';
import TemplateConfigurationModel from '../../../../shared/models/templating/api/TemplateConfigurationModel';
import ApplicationEnvironmentsContainer from '../../../../shared/state/containers/ApplicationEnvironmentsContainer';
import PanelHeaderContainer from '../../../../shared/components/structure/Panels/PanelHeaderContainer';
import PanelContainer from '../../../../shared/components/structure/Panels/PanelContainer';
import CustomScrollbars from '../../../../shared/components/structure/CustomScrollbars';
import Button from '../../../../shared/components/general/Button';
import TemplateReviewSection from '../../../../shared/scenes/applicationAppliedTemplateManager/components/TemplateReviewSection';
import Loader from '../../../../shared/components/general/Loader';
import { color_colors_growth, color_colors_growth_background, color_text_default } from '../../../../shared/constants/colors';
import UpdateAppliedApplicationTemplateFormsRequest from '../../../../shared/models/templating/api/UpdateAppliedApplicationTemplateFormsRequest';
import { v4 as uuidv4 } from 'uuid';
import SparkDeploymentContainer from '../../../../shared/hooks/SparkDeploymentContainer';
import { ApplicationType } from './ApplicationManagerContent';
import PageError from '../../../../shared/components/general/PageError';
import { breakpoint_small } from '../../../../shared/constants/breakpoints';

const completeIcon = require('../../../../shared/content/images/templates-icons/complete-thin.svg');
const incompleteIcon = require('../../../../shared/content/images/templates-icons/incomplete.svg');
const assistantIcon = require('../../../../shared/content/images/assistant-blue.svg');
const errorIcon = require('../../../../shared/content/images/alert-circle.svg');

interface AppliedAppTemplatesManagerProps {
    applicationId: string
    appContainer: ApplicationContainer
    appliedAppTemplateContainer: AppliedApplicationTemplateContainer
    incompleteSections: TemplateFormSectionModel[]
    history: any
    appliedAppTemplateId?: string
    appliedTemplates?: AppliedApplicationTemplateModel[]
    templateConfiguration?: TemplateConfigurationModel
    applicationEnvironmentsContainer: ApplicationEnvironmentsContainer
    afterSubmit: (appliedTemplateSyncId?: string) => void
    applicationType: ApplicationType
    phoneNumberDeploymentErrorText: string
    clearTelephonyDeploymentErrors: () => void
}

interface AppliedAppTemplatesManagerFormData {
    templateFormValues?: TemplateFormFieldValueModel[]
    appliedTemplates?: AppliedApplicationTemplateModel[]
    isSuccess: boolean
}

const InnerForm: React.FC<AppliedAppTemplatesManagerProps & FormikProps<AppliedAppTemplatesManagerFormData>> = (props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const trainingContainer = SparkDeploymentContainer.useContainer();

    useEffect(() => {
        props.applicationEnvironmentsContainer.loadEnvironments(props.applicationId);
    }, [props.applicationId])

    useEffect(() => {
        props.setFieldValue("appliedTemplates", props.appliedTemplates);
        if (isFormSubmitted) {
            setIsFormSubmitted(false);
        }
    }, [props.appliedTemplates]);

    const getSubmissionState = () => {
        const appTrainingState = trainingContainer.getAppDeploymentState(props.applicationId);
        if (appTrainingState === "training") {
            return "syncing";
        }
        if (props.appliedAppTemplateContainer.state.multipleTemplateSyncsStatus === "syncing") {
            return "syncing";
        }
        if (props.templateConfiguration?.isDisabled) {
            return "templateConfigDisabled";
        }
        if (isFormSubmitted) {
            if (props.appliedAppTemplateContainer.state.multipleTemplateSyncsStatus === "syncing" as MultipleTemplateSyncsStatus) {
                return "syncing";
            }
            if (props.appliedAppTemplateContainer.state.multipleTemplateSyncsStatus === "success" as MultipleTemplateSyncsStatus) {
                return "success";
            }
            if (props.appliedAppTemplateContainer.state.multipleTemplateSyncsStatus === "error" as MultipleTemplateSyncsStatus) {
                return "error";
            }
        }
        return "none";
    };

    const submitForm = () => {
        setIsFormSubmitted(true);
        props.submitForm();
    };

    const goBack = () => {
        props.history.goBack();
    };

    const incompleteSections = props.incompleteSections;

    const renderButton = () => {
        const submissionState = getSubmissionState();
        switch (submissionState) {
            case "none":
                return (
                    <Button
                        disabled={incompleteSections.length > 0}
                        themes={["primary-small"]}
                        text={"Finish"}
                        type="button"
                        onClick={submitForm}
                    />
                );
            case "success":
            case "syncing":
                return (
                    <Button
                        disabled={incompleteSections.length > 0}
                        themes={["primary-small"]}
                        text={"Home"}
                        type="button"
                        onClick={goBack} />
                );
            case "error":
                return (
                    <Button
                        disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                        loading={props.appliedAppTemplateContainer.state.isSaving}
                        themes={["primary-small"]}
                        text={"Retry"}
                        type="button"
                        onClick={submitForm} />
                );
        }
    };

    const renderSubmissionState = () => {
        const submissionState = getSubmissionState();
        switch (submissionState) {
            case "none":
                let description: string;
                if (incompleteSections.length > 0) {
                    if (incompleteSections.every(section => section.title)) {
                        description = `You have incomplete sections: ${incompleteSections.map(s => s?.title).join(', ')}`
                    } else if (!incompleteSections.every(section => section.title)) {
                        let updatedIncompleteSections: string = "";
                        for (const incompleteSection of incompleteSections) {
                            if (incompleteSection.title) {
                                updatedIncompleteSections = updatedIncompleteSections.concat(incompleteSection.title);
                            } else if (incompleteSection.name) {
                                updatedIncompleteSections = updatedIncompleteSections.concat(incompleteSection.name);
                            }
                        }
                        description = `You have incomplete sections: ${updatedIncompleteSections}`
                    }
                } else {
                    description = "It looks like you've filled in all the required fields."
                }

                return (
                    <>
                        <TemplateReviewSection title="Status Check" icon={incompleteSections.length > 0 ? incompleteIcon : completeIcon} description={description} />
                        <TemplateReviewSection
                            title="Generate Assistant"
                            icon={assistantIcon}
                            description={
                                incompleteSections.length > 0 ?
                                    "You need to complete all sections before you can finish."
                                    : "Click “Finish” to generate your assistant."
                            }
                        />
                        <div className="button-container">
                            <Button
                                disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                loading={props.appliedAppTemplateContainer.state.isSaving}
                                themes={["primary"]}
                                text={"Finish"}
                                type="button"
                                onClick={submitForm} />
                        </div>
                    </>
                );
            case "error":
                return (
                    <>
                        <TemplateReviewSection
                            title="Errors"
                            icon={errorIcon}
                            description={props.appliedAppTemplateContainer.state.templateSyncErrors.length > 0 ? props.appliedAppTemplateContainer.state.templateSyncErrors[0] : `There was a problem submitting your form.\n Try again in a few minutes.`}
                        />
                        <div className="button-container">
                            <Button disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving} loading={props.appliedAppTemplateContainer.state.isSaving}
                                themes={["primary"]}
                                text={"Retry"}
                                type="button"
                                onClick={submitForm} />
                        </div>
                    </>
                );
            case "syncing":
                return (
                    <>
                        <TemplateReviewSection
                            title="Updating Your Assistant..."
                            icon={assistantIcon}
                            description={`Your assistant is currently updating and should be ready within 5 minutes.\n Once ready, you can modify your form responses through the assistant dashboard at any time.`}
                        />
                        <div className="button-container">
                            <Button
                                disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                loading={props.appliedAppTemplateContainer.state.isSaving}
                                themes={["primary"]}
                                text={"Home"}
                                type="button"
                                onClick={goBack} />
                        </div>
                    </>
                );
            case "success":
                return (
                    <>
                        <TemplateReviewSection
                            title="All Done!"
                            icon={assistantIcon}
                            description={`Your assistant should be live within 5 minutes!\n Once ready, you can modify your form responses through the assistant dashboard at any time.`}
                        />
                        <div className="button-container">
                            <Button
                                disabled={incompleteSections.length > 0 || props.appliedAppTemplateContainer.state.isSaving}
                                loading={props.appliedAppTemplateContainer.state.isSaving}
                                themes={["primary"]}
                                text={"Home"}
                                type="button"
                                onClick={goBack} />
                        </div>
                    </>
                );
        }
    };

    return (
        <>
            {props.appliedAppTemplateContainer.state.isLoading ?
                <Loader />
                :
                <form onSubmit={props.handleSubmit}>
                    {props.phoneNumberDeploymentErrorText &&
                        <PageError
                            errors={[`${props.phoneNumberDeploymentErrorText}`]}
                            onClear={() => props.clearTelephonyDeploymentErrors()}
                        />
                    }
                    <PanelHeaderContainer>
                        <div className={buttonContainerStyle}>
                            {renderButton()}
                        </div>
                    </PanelHeaderContainer>
                    <PanelContainer>
                        <CustomScrollbars>
                            <div className={containerStyle}>
                                <h4>Review</h4>
                                {renderSubmissionState()}
                            </div>
                        </CustomScrollbars>
                    </PanelContainer>
                </form >
            }
        </>
    )
}

const buttonContainerStyle = css`
    margin-right: 16px;
    margin-left: auto;
    button {
        margin: 0 16px 0 0;
    }
`;

const containerStyle = css`
    padding: 32px;
    h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: ${color_text_default};
    }
    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .successful-sync {
        margin-top: 16px;
        padding: 16px;
        display: flex;
        background-color: ${color_colors_growth_background};
        border: solid 1px ${color_colors_growth};
        border-radius: 8px;
        img {
            margin-right: 16px;
        }
    }
`;

const AppliedAppTemplateReviewForm = withFormik<AppliedAppTemplatesManagerProps, AppliedAppTemplatesManagerFormData>({
    mapPropsToValues: props => (
        {
            appliedTemplates: [...props.appliedTemplates],
            isSuccess: false,
        }
    ),
    validationSchema: yup.object().shape({
    }),
    handleSubmit: async (values, { props }) => {
        const environmentId = props.applicationEnvironmentsContainer?.findPrimaryEnvironment(props.applicationId)?.id;

        try {
            const syncFormRequests: UpdateAppliedApplicationTemplateFormsRequest[] = [];
            const concurrentSyncKey = uuidv4();

            values.appliedTemplates.forEach((appliedTemplate) => {

                const locationSyncFormRequest: UpdateAppliedApplicationTemplateFormsRequest = {
                    appliedTemplateId: appliedTemplate.id,
                    templateFormFieldValues: appliedTemplate.templateFormFieldValues,
                    name: appliedTemplate.name,
                    publishToEnvironmentId: environmentId,
                    concurrentSyncKey: concurrentSyncKey
                };

                const commonSyncFormRequest: UpdateAppliedApplicationTemplateFormsRequest = {
                    appliedTemplateId: appliedTemplate.id,
                    templateFormFieldValues: appliedTemplate.templateFormFieldValues,
                    name: appliedTemplate.name,
                    concurrentSyncKey: concurrentSyncKey
                };

                if (props.applicationType === "location") {
                    syncFormRequests.push(locationSyncFormRequest);
                } else {
                    syncFormRequests.push(commonSyncFormRequest);
                }

            })

            const finalizeFormResult = await props.appliedAppTemplateContainer.finalizeForms(
                props.applicationId,
                syncFormRequests
            );

            const allSuccessful = finalizeFormResult.every(result => result.resultType === "Ok");

            if (!allSuccessful) {
                const syncInProgressError = "There are in process syncs for this applied template already. Please try again once existing syncs are complete."
                if (props.appliedAppTemplateContainer.state.templateSyncErrors.includes(syncInProgressError)) {
                    props.appliedAppTemplateContainer.setState({ ...props.appliedAppTemplateContainer.state, templateSyncErrors: ["Looks like there's another assistant update underway. Please wait for it to finish and try again in a few minutes."] });
                }
            }
            else {
                const appliedTemplateSyncId = finalizeFormResult[0]?.data?.id;
                if (props.applicationType === "common" && appliedTemplateSyncId) {
                    props.afterSubmit(appliedTemplateSyncId);
                } else {
                    props.afterSubmit();
                }
            }
        } catch (error) { }
    }
})(InnerForm);


export default AppliedAppTemplateReviewForm;